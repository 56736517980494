<template>
<v-container>
    <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
    <v-form
            ref="moduleForm"
            v-model="valid"          
        >
        <v-row>
            <v-col>
            <v-text-field  :dark="$store.getters.getColorPalette().isDark" 
           :color="$store.getters.getColorPalette().inputtextColor"
                        :loading="loading"
                        v-model="form.name"
                        :counter="25"
                        :rules="nameRules"
                        :label="$store.getters.getTextMap().name"
                        required
                    ></v-text-field>
            <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
        </v-col>
            <v-col>
            <v-text-field  :dark="$store.getters.getColorPalette().isDark" 
          :color="$store.getters.getColorPalette().inputtextColor"
                        :loading="loading"
                        v-model="form.msg"
                        :counter="55"
                        :rules="msgRules"
                        :label="$store.getters.getTextMap().description"
                        required
                    ></v-text-field>
            <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
        </v-col>
        <v-col>
            <v-select :dark="$store.getters.getColorPalette().isDark" 
           :color="$store.getters.getColorPalette().inputtextColor"
                v-if="!loading"
              v-model="form.hub_id"
          :items="hubOptions"
          :label="$store.getters.getTextMap().edge_hub"
          dense
          filled
          item-text="label"
          item-value="value"
          required
            :rules="selectRules"  :menu-props="{dark: $store.getters.getColorPalette().isDark}"
        ></v-select>
            <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
        </v-col>
        </v-row>
        <v-row>
            <v-col align-self="center">
                <v-skeleton-loader
                    v-if="loading"
                    class="mx-auto"
                    type="text"
                ></v-skeleton-loader>
                <v-text-field  :dark="$store.getters.getColorPalette().isDark" 
           :color="$store.getters.getColorPalette().inputtextColor"
                    v-if="!loading"
                    v-model="form.ss_volt"
                    :counter="10"
                    :rules="[rules.required,rules.unum]"
                    :label="$store.getters.getTextMap().substation_voltage_kv"
                    required
                ></v-text-field>
            </v-col>
            <v-col align-self="center">
                <v-skeleton-loader
                    v-if="loading"
                    class="mx-auto"
                    type="text"
                ></v-skeleton-loader>
                <v-text-field :dark="$store.getters.getColorPalette().isDark" 
          :color="$store.getters.getColorPalette().inputtextColor"
                    v-if="!loading"
                    v-model="form.ss_tr"
                    :counter="5"
                    :rules="[rules.required,rules.unum]"
                    :label="$store.getters.getTextMap().transformer_rating_mva"
                    hint="Upstream Transformer Rating at substation(MVA)"
                    required
                ></v-text-field>
            </v-col>
            <v-col align-self="center">
                <v-skeleton-loader
                    v-if="loading"
                    class="mx-auto"
                    type="text"
                ></v-skeleton-loader>
                <v-text-field  :dark="$store.getters.getColorPalette().isDark" 
            :color="$store.getters.getColorPalette().inputtextColor"
                    v-if="!loading"
                    v-model="form.t_impedance"
                    :counter="4"
                    :rules="[rules.required,rules.unum]"
                    :label="$store.getters.getTextMap().percentage_impedance_of_the_transformer"
                    required
                ></v-text-field>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col>
                <v-select  :dark="$store.getters.getColorPalette().isDark" 
          :color="$store.getters.getColorPalette().inputtextColor"
                    class="mx-1"
                    v-if="!loading"
                    v-model="workspace"
                    :items="workspaceOptions"
                    :label="$store.getters.getTextMap().workspace"
                    dense
                    outlined
                    item-text="label"
                    item-value="value"
                    required  :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                ></v-select>
        </v-col>
            <v-col>
                <v-select  :dark="$store.getters.getColorPalette().isDark" 
           :color="$store.getters.getColorPalette().inputtextColor"
                class="mx-1"
                v-if="!loading"
                v-model="machine"
                :items="machineOptions"
                :label="$store.getters.getTextMap().machine"
                outlined
                dense
                item-text="label"
                item-value="value"
                required
                    :rules="objectRules"  :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                ></v-select>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col >
                <v-skeleton-loader
                    v-if="loading"
                    class="mx-auto"
                    type="text"
                ></v-skeleton-loader>
                <v-select  :dark="$store.getters.getColorPalette().isDark" 
            :color="$store.getters.getColorPalette().inputtextColor"
                    class="mx-1"
                    v-if="!loading"
                    v-model="form.v_ry"
                    :items="paramOptions"
                    :label="$store.getters.getTextMap().voltage_rv_y"
                    outlined
                    dense
                    item-text="label"
                    item-value="value"
                    :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                    required
                    :rules="[rules.required,rules.nonZeroLength]"
                ></v-select>
            </v-col>
            <v-col>
                <v-skeleton-loader
                    v-if="loading"
                    class="mx-auto"
                    type="text"
                ></v-skeleton-loader>
                <v-select :dark="$store.getters.getColorPalette().isDark" 
            :color="$store.getters.getColorPalette().inputtextColor"
                    v-if="!loading"
                    class="mx-1"
                    v-model="form.v_yb"
                    :items="paramOptions"
                    :label="$store.getters.getTextMap().voltage_yb_v"
                    outlined
                    dense
                    item-text="label"
                    item-value="value"
                   :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                    required
                    :rules="[rules.required,rules.nonZeroLength]"
                ></v-select>
            </v-col>
            <v-col>
                <v-skeleton-loader
                    v-if="loading"
                    class="mx-auto"
                    type="text"
                ></v-skeleton-loader>
                <v-select :dark="$store.getters.getColorPalette().isDark"
            :color="$store.getters.getColorPalette().inputtextColor"
                    v-if="!loading"
                    class="mx-1"
                    v-model="form.v_br"
                    :items="paramOptions"
                    :label="$store.getters.getTextMap().voltage_br_v"
                    outlined
                    dense
                    item-text="label"
                    item-value="value"
                    :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                    required
                    :rules="[rules.required,rules.nonZeroLength]"
                ></v-select>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col >
                <v-skeleton-loader
                    v-if="loading"
                    class="mx-auto"
                    type="text"
                ></v-skeleton-loader>
                <v-select  :dark="$store.getters.getColorPalette().isDark" 
            :color="$store.getters.getColorPalette().inputtextColor"
                    class="mx-1"
                    v-if="!loading"
                    v-model="form.current_r"
                    :items="paramOptions"
                    :label="$store.getters.getTextMap().current_r_a"
                    outlined
                    dense
                    item-text="label"
                    item-value="value"
                    :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                    required
                    :rules="[rules.required,rules.nonZeroLength]"
                ></v-select>
            </v-col>
            <v-col>
                <v-skeleton-loader
                    v-if="loading"
                    class="mx-auto"
                    type="text"
                ></v-skeleton-loader>
                <v-select :dark="$store.getters.getColorPalette().isDark"
            :color="$store.getters.getColorPalette().inputtextColor"
                    v-if="!loading"
                    class="mx-1"
                    v-model="form.current_y"
                    :items="paramOptions"
                    :label="$store.getters.getTextMap().current_y_a"
                    outlined
                    dense
                    item-text="label"
                    item-value="value"
                    :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                    required
                    :rules="[rules.required,rules.nonZeroLength]"
                ></v-select>
            </v-col>
            <v-col>
                <v-skeleton-loader
                    v-if="loading"
                    class="mx-auto"
                    type="text"
                ></v-skeleton-loader>
                <v-select  :dark="$store.getters.getColorPalette().isDark" 
            :color="$store.getters.getColorPalette().inputtextColor"
                    v-if="!loading"
                    class="mx-1"
                    v-model="form.current_b"
                    :items="paramOptions"
                    :label="$store.getters.getTextMap().current_b_a"
                    outlined
                    dense
                    item-text="label"
                    item-value="value"
                     :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                    required
                    :rules="[rules.required,rules.nonZeroLength]"
                ></v-select>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col>
                <v-skeleton-loader
                    v-if="loading"
                    class="mx-auto"
                    type="text"
                ></v-skeleton-loader>
                <v-select :dark="$store.getters.getColorPalette().isDark" 
           :color="$store.getters.getColorPalette().inputtextColor"
                    v-if="!loading"
                    class="mx-1"
                    v-model="form.thd_i"
                    :items="paramOptions"
                    :label="$store.getters.getTextMap().thd_i"
                    outlined
                    dense
                    item-text="label"
                    item-value="value"
                    :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                    
                ></v-select>
            </v-col>
            <v-col>
                <v-skeleton-loader
                    v-if="loading"
                    class="mx-auto"
                    type="text"
                ></v-skeleton-loader>
                <v-select  :dark="$store.getters.getColorPalette().isDark" 
            :color="$store.getters.getColorPalette().inputtextColor"
                    v-if="!loading"
                    class="mx-1"
                    v-model="form.thd_v"
                    :items="paramOptions"
                    :label="$store.getters.getTextMap().thd_v"
                    outlined
                    dense
                    item-text="label"
                    item-value="value"
                    :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                    
                ></v-select>
            </v-col>
            <v-col>
                <v-skeleton-loader
                    v-if="loading"
                    class="mx-auto"
                    type="text"
                ></v-skeleton-loader>
                <v-select :dark="$store.getters.getColorPalette().isDark" 
            :color="$store.getters.getColorPalette().inputtextColor"
                    v-if="!loading"
                    class="mx-1"
                    v-model="form.thd_i1"
                    :items="paramOptions"
                    :label="$store.getters.getTextMap().thd_i1"
                    outlined
                    dense
                    item-text="label"
                    item-value="value"
                   :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                    
                ></v-select>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col>
                <v-skeleton-loader
                    v-if="loading"
                    class="mx-auto"
                    type="text"
                ></v-skeleton-loader>
                <v-select  :dark="$store.getters.getColorPalette().isDark" 
           :color="$store.getters.getColorPalette().inputtextColor"
                    v-if="!loading"
                    class="mx-1"
                    v-model="form.active_pw"
                    :items="paramOptions"
                    :label="$store.getters.getTextMap().active_power_kw"
                    outlined
                    dense
                    item-text="label"
                    item-value="value"
                    :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                    required
                    :rules="[rules.required,rules.nonZeroLength]"
                ></v-select>
            </v-col>
            <v-col>
                <v-skeleton-loader
                    v-if="loading"
                    class="mx-auto"
                    type="text"
                ></v-skeleton-loader>
                <v-select :dark="$store.getters.getColorPalette().isDark" 
           :color="$store.getters.getColorPalette().inputtextColor"
                    v-if="!loading"
                    class="mx-1"
                    v-model="form.apparent_pw"
                    :items="paramOptions"
                    :label="$store.getters.getTextMap().apparent_power_kva"
                    outlined
                    dense
                    item-text="label"
                    item-value="value"
                   :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                    required
                    :rules="[rules.required,rules.nonZeroLength]"
                ></v-select>
            </v-col>
            <v-col>
                <v-skeleton-loader
                    v-if="loading"
                    class="mx-auto"
                    type="text"
                ></v-skeleton-loader>
                <v-select :dark="$store.getters.getColorPalette().isDark"
            :color="$store.getters.getColorPalette().inputtextColor"
                    v-if="!loading"
                    class="mx-1"
                    v-model="form.reactive_pw"
                    :items="paramOptions"
                    :label="$store.getters.getTextMap().reactive_power_kvar"
                    outlined
                    dense
                    item-text="label"
                    item-value="value"
                     :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                    required
                    :rules="[rules.required,rules.nonZeroLength]"
                ></v-select>
            </v-col>
            
        </v-row>
        <v-row no-gutters>
            <v-col>
                <v-skeleton-loader
                    v-if="loading"
                    class="mx-auto"
                    type="text"
                ></v-skeleton-loader>
                <v-select  :dark="$store.getters.getColorPalette().isDark" 
           :color="$store.getters.getColorPalette().inputtextColor"
                    v-if="!loading"
                    class="mx-1"
                    v-model="form.active_energy"
                    :items="paramOptions"
                    :label="$store.getters.getTextMap().active_energy_kwh"
                    outlined
                    dense
                    item-text="label"
                    item-value="value"
                   :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                    required
                    :rules="[rules.required,rules.nonZeroLength]"
                ></v-select>
            </v-col>
            
            <v-col>
                <v-skeleton-loader
                    v-if="loading"
                    class="mx-auto"
                    type="text"
                ></v-skeleton-loader>
                <v-select :dark="$store.getters.getColorPalette().isDark" 
           :color="$store.getters.getColorPalette().inputtextColor"
                    v-if="!loading"
                    class="mx-1"
                    v-model="form.apparent_energy"
                    :items="paramOptions"
                    :label="$store.getters.getTextMap().apparent_energy_kvah"
                    outlined
                    dense
                    item-text="label"
                    item-value="value"
                   :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                    required
                    :rules="[rules.required,rules.nonZeroLength]"
                ></v-select>
            </v-col>
            <v-col>
                <v-skeleton-loader
                    v-if="loading"
                    class="mx-auto"
                    type="text"
                ></v-skeleton-loader>
                <v-select  :dark="$store.getters.getColorPalette().isDark" 
            :color="$store.getters.getColorPalette().inputtextColor"
                    v-if="!loading"
                    class="mx-1"
                    v-model="form.pf"
                    :items="paramOptions"
                    :label="$store.getters.getTextMap().pf"
                    outlined
                    dense
                    item-text="label"
                    item-value="value"
                   :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                    required
                    :rules="[rules.required,rules.nonZeroLength]"
                ></v-select>
            </v-col>
        </v-row>
        <v-row>
            <v-col align="center">
                        <v-skeleton-loader
                            v-if="loading"
                            class="mx-auto"
                            type="text"
                        ></v-skeleton-loader>
                    <v-btn
                    v-if="!loading"
                     :color="$store.getters.getColorPalette().submitbtnColor"
                      
                        @click="onSubmit"
                    >
                        {{ $store.getters.getTextMap().submit }}
                    </v-btn>
                </v-col>
                <v-col align="center">
                    <v-skeleton-loader
                            v-if="loading"
                            class="mx-auto"
                            type="text"
                        ></v-skeleton-loader>
                    <v-btn
                    v-if="!loading"
                       :color="$store.getters.getColorPalette().cancelbtnColor"
                       :dark="$store.getters.getColorPalette().isDark" 
                        @click="close"
                    >
                    {{ $store.getters.getTextMap().cancel }}
                    </v-btn>
                </v-col>
        </v-row>

</v-form>
</v-container>
</template>

<script>
import axios from 'axios'
import InfoAlert from '@/components/InfoAlert'
import FormRules from '@/utillities/FormRules';
export default {
    name:'CreatePQModule',
    props:['pqModule'],
    components:{
        InfoAlert
    },
    mounted(){
        if(this.pqModule && this.pqModule.module_id){
            this.form=Object.assign({},this.pqModule)
            this.edit=true
            this.api='updatePqModule'
        }else{
            this.edit=false
            this.api='createPqModule'
        }
    },
    data(){
        return {
            loading:false,
            info:'',
            showDismissibleAlert:false,
            valid:false,
            api:'createPqModule',
            workspace:null,
            machine:null,
            form:{
                name:null,
                module_type:'pq',
                
            },
            nameRules: [
                v => !!v || 'Name is required',
                v => /\S+/.test(v) || 'Name is required',
                v => (v && v.length <= 25 && v.length > 0) || 'Name must be less than 25 characters',
            ],
            msgRules: [
                v => !!v || 'Description is required',
                v => (v && v.length <= 55 && v.length > 0) || 'Description must be less than 55 characters',
            ],
            selectRules:[
                v=> (v!=null) || 'Required'
            ],
            typeOptions:[
                {label:this.$store.getters.getTextMap().text,value:'text'},
                {label:this.$store.getters.getTextMap().parameter,value:'param'}
            ],
            machineOptions:[],
            paramOptions:[],
            rules:FormRules.rules,
            objectRules:FormRules.objectRules,
            numberRules:FormRules.numberRulesDynamic(6)
        }
    },
    computed:{
        
        notificationGroups(){
            let op=[]
            for(let i of this.$store.state.notificationGroups){
                op.push({
                    label:i.name,
                    value:i.group_id
                })
            }
            return op
        },
        hubOptions(){
            let op=[]
            for(let i of this.$store.state.hubs){
                op.push({
                    label:i.hub_id,
                    value:i.hub_id
                })
            }
            return op
        },
        workspaceOptions(){
            let op=[]
          if(this.form.hub_id){
              let g=this.$store.getters.getWorkspacesByHubId(this.form.hub_id)
              for(let i of g){
                  //op.push({value:i, text: i.name })
                  op.push( {value:i, label: i.name })
                }
          }
          return op
        },
    },
    methods:{
        getMachineList(){
            let op=[]
            if(this.workspace){
                let payload={
                    workspace_id:this.workspace.workspace_id
                }
                //console.log(payload)
                this.loading = true;
      axios.post(this.$store.state.api + "getMachinesByWorkspace", payload,{headers: {
            Authorization: 'Bearer '+ this.$store.state.jwt
            }})
                .then(response => {
                if (response.data.status == "success") {
                    
                    
                    //console.log(response.data);
                    
                    let machines=response.data.machines
                //this.loading = false;
                for(let i of machines){
                  
                  if(i && i.name){
                    op.push({value:i.machine_id, label: i.name })
                  }else{
                    op.push({value:i.machine_id, label: i.machine_id })
                  }
                }
                    //console.log(op)
                } else {
                    this.info = response.data.msg;
                    this.showDismissibleAlert = true;
                }
                //this.onReset()
                this.loading = false;
                })
                .catch(error => {
                this.info = error;
                this.showDismissibleAlert = true;
                this.loading = false;
                });
            }
            this.machineOptions=op
        },
        getParams(){
            //console.log('getParams called')
            //console.log(this.machine)
            if(this.machine ){
                this.loading=true
                //console.log('getParams executed')
                axios.post(this.$store.state.api+'getParamsByMachine',{machine_id:this.machine},{headers: {
                    Authorization: 'Bearer '+ this.$store.state.jwt
                    }})
                    .then(response=>{
                        //console.log(response.data)
                        if(response.data.status=='success'){
                            this.loading=false
                            //this.$store.dispatch('refreshViews')
                            this.paramOptions=[]
                            for(let i of response.data.params){
                                this.paramOptions.push({label:i.name,value:i.param_id})
                            }
                            
                        }else{
                            this.loading=false
                            this.info=response.data.msg
                            this.showDismissibleAlert=true
                        }
                    }).catch(err=>{
                        this.loading=false
                        this.info=err
                        this.showDismissibleAlert=true
                        
                    })
            }
        },
        getParamName(param){
          let p=this.$store.getters.getParameterById(param)
          if(p){
          return this.$store.getters.getDeviceById(p['device_id'])['name'] +"-"+p['name']
          }else{
            return "Issue with getting Param Name"
          }
        },
        onSubmit(){
            this.$refs.moduleForm.validate()
            if(this.valid){
                 this.loading=true
                //let d=Object.assign({}, this.form)
                //console.log(d)
                //d['data']={headers:[],params:[]}
                axios.post(this.$store.state.api+this.api,this.form,{headers: {
                    Authorization: 'Bearer '+ this.$store.state.jwt
                    }})
                    .then(response=>{
                        if(response.data.status=='success'){
                            this.loading=false
                            this.$store.dispatch('refreshModules')
                            //this.$refs.moduleForm.reset()
                            this.close()
                        }else{
                            this.loading=false
                            this.info=response.data.msg
                            this.showDismissibleAlert=true
                        }
                    }).catch(err=>{
                        this.loading=false
                        this.info=err
                        this.showDismissibleAlert=true
                        
                    })
            }
        },
        close(){
            this.$emit('close')
        }
    },
    watch:{
        workspace(){
            this.getMachineList()
        },
        machine(){
            this.getParams()
        },
    }
}
</script>