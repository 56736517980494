<template>
    <v-container fluid :style="{'background-color': $store.getters.getColorPalette().backgroundColorCode, color: $store.getters.getColorPalette().accentCode}">
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
      <v-card :dark="$store.getters.getColorPalette().isDark">
        <v-card-title>
          {{$store.getters.getTextMap().emissions_kg_co2}}
 
        </v-card-title>
       
        <v-simple-table
    fixed-header

  >
    <template v-slot:default>
      <thead>
        <tr>
          <th :class=" 'text-capitalize font-weight-black '+$store.getters.getColorPalette().tableHeaderColorName ">
            {{$store.getters.getTextMap().kpis}}

          </th>
          <th :class=" 'text-capitalize font-weight-black '+$store.getters.getColorPalette().tableHeaderColorName ">
            {{$store.getters.getTextMap().jan_23}}
          </th>
          <th :class=" 'text-capitalize font-weight-black '+$store.getters.getColorPalette().tableHeaderColorName ">
            {{$store.getters.getTextMap().feb_23}}
          </th>
          <th :class=" 'text-capitalize font-weight-black '+$store.getters.getColorPalette().tableHeaderColorName ">
            {{$store.getters.getTextMap().mar_23}}
          </th>
          <th :class=" 'text-capitalize font-weight-black '+$store.getters.getColorPalette().tableHeaderColorName ">
            {{$store.getters.getTextMap().apr_23}}
          </th>
          <th :class=" 'text-capitalize font-weight-black '+$store.getters.getColorPalette().tableHeaderColorName ">
            {{$store.getters.getTextMap().may_23}}
          </th>
          <th :class=" 'text-capitalize font-weight-black '+$store.getters.getColorPalette().tableHeaderColorName ">
            {{$store.getters.getTextMap().jun_23}}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="item in emissionData"
          :key="item.kpi_tag"
          @click="showTable(item)"
        >
          <td>{{ item.kpi_tag }}</td>
          <td>{{ item['Jan-23'] }}</td>
          <td>{{ item['Feb-23'] }}</td>
          <td>{{ item['Mar-23'] }}</td>
          <td>{{ item['Apr-23'] }}</td>
          <td>{{ item['May-23'] }}</td>
          <td>{{ item['Jun-23'] }}</td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
      </v-card>
      <v-dialog v-model="drillDownVisible" fullscreen hide-overlay transition="dialog-bottom-transition">
            <v-card  :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().backgroundColorCode">
                <v-toolbar :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().foregroundColorCode">
                    <v-btn icon :dark="$store.getters.getColorPalette().isDark" @click="drillDownVisible = false"><v-icon>mdi-close</v-icon></v-btn>
                    <v-toolbar-title>{{ drillDown?drillDown.kpi_tag:"Details" }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn :dark="$store.getters.getColorPalette().isDark" text @click="drillDownVisible = false">{{$store.getters.getTextMap().close}}
</v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <Co2DrillDownTable :emissionDetail="drillDown"/>
                
            </v-card>
      </v-dialog>
    </v-container>
    </template>
    
    <script>
    // import axios from 'axios'
    import InfoAlert from '@/components/InfoAlert'
    import Co2DrillDownTable from '@/components/tables/esg/Co2DrillDownTable'
    // import EditModbusParameter from '@/components/crud_components/EditModbusParameter'
    import esgData from '@/data/esg_data'
    
    export default {
        name:'ModbusParamViewTable',
        components:{
            InfoAlert,
            Co2DrillDownTable,
            // EditModbusParameter
        },
        mounted(){
            // this.getData()
        },
        data(){
            return {
                emissionData:esgData.consolidated,
                search:'',
                info:'',
                showDismissibleAlert:false,
                loading:false,
                drillDownVisible:false,
                drillDown:null,
                expanded: [],
                headers: [
              
              { text: 'KPIs', value: 'kpi_tag', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
              { text: 'Jan-23', value: 'Jan-23', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
              { text: 'Feb-23', value: 'Feb-23', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
              { text: 'Mar-23', value: 'Mar-23', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
              { text: 'Apr-23', value: 'Apr-23', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
              { text: 'May-23', value: 'May-23', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
              { text: 'June-23', value: 'June-23', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
              
              
              
            ],
            }
        },
        computed:{

            
        },
        methods:{
            showTable(item){
                this.drillDownVisible=true
                this.drillDown=item

            }
        }
    }
    </script>
    <style scoped>
    .v-card__title{
      background-color:#bdbdbd00;
    }
    .theme--light.v-toolbar.v-sheet{
      background-color: #B2DFDB00;
    }
    .theme--light.v-data-table{
       background-color: #B2DFDB00;
    }
    </style>