	
export default {
    "consolidated":[
        {"kpi_tag":"Electricity","kpi":"eb","Jan-23":36522, "Feb-23":37495, "Mar-23":43171.6,	"Apr-23":42325 },
        {"kpi_tag":"DG Operation","kpi":"diesel","Jan-23":3890, "Feb-23":1089, "Mar-23":4124,	"Apr-23":2064 },
        {"kpi_tag":"LPG - Furance Operation","kpi":"lpg","Jan-23":3343, "Feb-23":3522, "Mar-23":3612,	"Apr-23":3600 },
        {"kpi_tag":"Vehicles - Petrol","kpi":"petrol","Jan-23":213, "Feb-23":220, "Mar-23":544.37,	"Apr-23":325 },
        {"kpi_tag":"Vehicles - Diesel","kpi":"diesel","Jan-23":0, "Feb-23":0, "Mar-23":0,	"Apr-23":0 },
        {"kpi_tag":"Round Trip Vehicles - Chennai to Ahmedabad","Jan-23":11100, "Feb-23":11510, "Mar-23":9190,	"Apr-23":8730 },
        {"kpi_tag":"Round Trip Vehicles - Chennai to Coimbatore","Jan-23":19100, "Feb-23":19300, "Mar-23":21190,	"Apr-23":20860 },
    ],
    "details":{
        "Electricity":[
            {"timestamp":"Jan-23", "consumption_chen":12094, "consumption_amd":3941, 
             "consumption_oth":33386,"EMISSION FACTOR OF CO2 Emissions (gCO2/KWh)":739},
            {"timestamp":"Feb-23", "consumption_chen":10256, "consumption_amd":4211, 
            "consumption_oth":36270,"EMISSION FACTOR OF CO2 Emissions (gCO2/KWh)":739},
            {"timestamp":"Mar-23", "consumption_chen":10707, "consumption_amd":5287, 
            "consumption_oth":42425,"EMISSION FACTOR OF CO2 Emissions (gCO2/KWh)":739},
            {"timestamp":"Apr-23", "consumption_chen":10256, "consumption_amd":4211, 
            "consumption_oth":43814,"EMISSION FACTOR OF CO2 Emissions (gCO2/KWh)":739}

        ],
        "DG Operation":[
            {"timestamp":"Jan-23", "consumption_chen":925, "consumption_amd":1027, "consumption_oth":824, 
        "DENSITY (kg/l)":0.88, "Conversion Factor for Energy Content (MWH/Tons)":11.94, "EMISSION FACTOR OF CO2 Emissions (KgCO2/GJ)":74.10},
            {"timestamp":"Feb-23", "consumption_chen":379, "consumption_amd":263, "consumption_oth":135, 
        "DENSITY (kg/l)":0.88, "Conversion Factor for Energy Content (MWH/Tons)":11.94, "EMISSION FACTOR OF CO2 Emissions (KgCO2/GJ)":74.10},
            {"timestamp":"Mar-23", "consumption_chen":447, "consumption_amd":205, "consumption_oth":2291,
        "DENSITY (kg/l)":0.88, "Conversion Factor for Energy Content (MWH/Tons)":11.94, "EMISSION FACTOR OF CO2 Emissions (KgCO2/GJ)":74.10},
            {"timestamp":"Apr-23", "consumption_chen":1075, "consumption_amd":129, "consumption_oth":269,
        "DENSITY (kg/l)":0.88, "Conversion Factor for Energy Content (MWH/Tons)":11.94, "EMISSION FACTOR OF CO2 Emissions (KgCO2/GJ)":74.10}

        ],
        "LPG - Furance Operation":[
            {"timestamp":"Jan-23", "consumption":1.12, "Conversion Factor for Energy Content (MWH/Tons)":13.14, "EMISSION FACTOR OF CO2 Emissions (KgCO2/GJ)":63.10},
            {"timestamp":"Feb-23", "consumption":1.18, "Conversion Factor for Energy Content (MWH/Tons)":13.14, "EMISSION FACTOR OF CO2 Emissions (KgCO2/GJ)":63.10},
            {"timestamp":"Mar-23", "consumption":1.20, "Conversion Factor for Energy Content (MWH/Tons)":13.14, "EMISSION FACTOR OF CO2 Emissions (KgCO2/GJ)":63.10},
            {"timestamp":"Apr-23", "consumption":1.20, "Conversion Factor for Energy Content (MWH/Tons)":13.14, "EMISSION FACTOR OF CO2 Emissions (KgCO2/GJ)":63.10}


        ],
        "Vehicles - Petrol":[
            {"timestamp":"Jan-23", "consumption":79.12, "Conversion Factor for Energy Content (MWH/Tons)":11.94, "EMISSION FACTOR OF CO2 Emissions (KgCO2/GJ)":74.10},
            {"timestamp":"Feb-23", "consumption":81.52, "Conversion Factor for Energy Content (MWH/Tons)":11.94, "EMISSION FACTOR OF CO2 Emissions (KgCO2/GJ)":74.10},
            {"timestamp":"Mar-23", "consumption":201.59, "Conversion Factor for Energy Content (MWH/Tons)":11.94, "EMISSION FACTOR OF CO2 Emissions (KgCO2/GJ)":74.10},
            {"timestamp":"Apr-23", "consumption":120.30, "Conversion Factor for Energy Content (MWH/Tons)":11.94, "EMISSION FACTOR OF CO2 Emissions (KgCO2/GJ)":74.10}


        ],
        "Vehicles - Diesel":[

        ],
        "Round Trip Vehicles - Chennai to Ahmedabad":[

        ],
        "Round Trip Vehicles - Chennai to Coimbatore":[

        ]


    },
    "factors":{
        "lpg":{
            "conversion_factors":{
            "kwh/ton":11.94, "kgco2/gj":74.10,"kgco2/kwh":0.22716
            }
        },
        "eb":{
            "conversion_factors":{
                "kgco2/kwh":0.739
                }
        },
        "diesel":{
            "conversion_factors":{
                "density":0.88, "kwh/kg":11.94, "kgco2/kwh":0.26676
            }
        },
        "petrol":{
            "conversion_factors":{
                "density":0.88, "kwh/kg":12.30, "kgco2/kwh":0.24948
            }
        }
    }
    
}
