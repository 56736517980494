var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{style:({'background-color': _vm.$store.getters.getColorPalette().backgroundColorCode, color: _vm.$store.getters.getColorPalette().accentCode}),attrs:{"fluid":""}},[_c('InfoAlert',{attrs:{"showDismissibleAlert":_vm.showDismissibleAlert,"info":_vm.info},on:{"close":function($event){_vm.showDismissibleAlert=false}}}),_c('v-card',{attrs:{"dark":_vm.$store.getters.getColorPalette().isDark}},[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$store.getters.getTextMap().search,"single-line":"","hide-details":"","dark":_vm.$store.getters.getColorPalette().isDark},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.tableData,"item-key":"timestamp","search":_vm.search,"loading":_vm.loading,"dark":_vm.$store.getters.getColorPalette().isDark,"loading-text":"Loading... Please wait"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$store.getters.getTextMap().diesel_emissions)+" ")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer')],1)]},proxy:true},{key:"item.consumption_total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s((item.consumption_chen + item.consumption_amd + item.consumption_oth)/2)+" ")]}},{key:"item.conversion_factor",fn:function(ref){return [_vm._v(" "+_vm._s(_vm.emissionData['factors']['diesel']['conversion_factors']["kgco2/kwh"])+" ")]}},{key:"item.density",fn:function(ref){return [_vm._v(" "+_vm._s(_vm.emissionData['factors']['diesel']['conversion_factors']['density'])+" ")]}},{key:"item.mass",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(Math.round(((item.consumption_chen + item.consumption_amd + item.consumption_oth)/2)* _vm.emissionData['factors']['diesel']['conversion_factors']["density"]*100)/100)+" ")]}},{key:"item.energy",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(Math.round(((item.consumption_chen + item.consumption_amd + item.consumption_oth)/2)* _vm.emissionData['factors']['diesel']['conversion_factors']["density"]*_vm.emissionData['factors']['diesel']['conversion_factors']["kwh/kg"]*100)/100)+" ")]}},{key:"item.emission",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(Math.round(((item.consumption_chen + item.consumption_amd + item.consumption_oth)/2)* _vm.emissionData['factors']['diesel']['conversion_factors']["density"]*_vm.emissionData['factors']['diesel']['conversion_factors']["kwh/kg"]*_vm.emissionData['factors']['diesel']['conversion_factors']["kgco2/kwh"]*100)/100)+" ")]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }