<template>
    <v-container  >
    <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
    <v-form
            ref="moduleForm"
            v-model="valid"          
        >
        <v-row>
            <v-col>
            <v-text-field
            :dark="$store.getters.getColorPalette().isDark" 
      :color="$store.getters.getColorPalette().inputtextColor"
                        :loading="loading"
                        v-model="form.name"
                        :counter="25"
                        :rules="nameRules"
                        :label="$store.getters.getTextMap().name"
                        required
                    ></v-text-field>
            <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
        </v-col>
            <v-col>
            <v-text-field  :dark="$store.getters.getColorPalette().isDark" 
           :color="$store.getters.getColorPalette().inputtextColor"
                        :loading="loading"
                        v-model="form.msg"
                        :counter="55"
                        :rules="msgRules"
                        :label="$store.getters.getTextMap().description"
                        required
                    ></v-text-field>
            <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
        </v-col>
        <v-col>
            <v-select  :dark="$store.getters.getColorPalette().isDark" 
      :color="$store.getters.getColorPalette().inputtextColor"
                v-if="!loading"
              v-model="form.hub_id"
          :items="hubOptions"
          :label="$store.getters.getTextMap().edge_hub"
          dense
          filled
          item-text="label"
          item-value="value"
          required
            :rules="selectRules"  :menu-props="{dark: $store.getters.getColorPalette().isDark}"
        ></v-select>
            <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
        </v-col>
        </v-row>

        <v-row no no-gutters>
            <v-col><span class="text-subtitle-2" :style="{'color':$store.getters.getColorPalette().accentCode}">{{ $store.getters.getTextMap().temperature_parameters }}:</span></v-col>
        </v-row>
<v-divider></v-divider>
        <v-row >
            <v-col align-self="center">
                <v-text-field  :dark="$store.getters.getColorPalette().isDark" 
            :color="$store.getters.getColorPalette().inputtextColor"
                        :loading="loading"
                        v-model="form.tolerance"
                        :counter="6"
                        :rules="numberRules"
                        :label="$store.getters.getTextMap().tolerance"
                        required
                ></v-text-field>
                <v-skeleton-loader
                    v-if="loading"
                    class="mx-auto"
                    type="text"
                ></v-skeleton-loader>
            </v-col>
            <v-col align-self="center">
                <v-select  :dark="$store.getters.getColorPalette().isDark" 
          :color="$store.getters.getColorPalette().inputtextColor"
                v-if="!loading"
              v-model="form.ambient_temp_type"
          :items="typeOptions"
          :label="$store.getters.getTextMap().ambient_temp_input_type"
          dense
          filled
          item-text="label"
          item-value="value"
          required
            :rules="objectRules" :menu-props="{dark: $store.getters.getColorPalette().isDark}"
        ></v-select>
        <v-skeleton-loader 
                    v-if="loading"
                    class="mx-auto"
                    type="text"
                ></v-skeleton-loader>
            </v-col>
            <v-col v-if="form.ambient_temp_type=='text'" align-self="center">
                <v-text-field  :dark="$store.getters.getColorPalette().isDark" 
           :color="$store.getters.getColorPalette().inputtextColor"
                        :loading="loading"
                        v-model="form.ambient_temp"
                        :counter="6"
                        :rules="numberRules"
                        :label="$store.getters.getTextMap().ambient_temp"
                        required
                ></v-text-field>
            </v-col>
        </v-row>
        <v-row v-if="form.ambient_temp_type=='param'">
            <v-col>
                <v-select   :dark="$store.getters.getColorPalette().isDark" 
            :color="$store.getters.getColorPalette().inputtextColor"
                v-if="!loading"
              v-model="workspace3"
          :items="workspaceOptions"
          :label="$store.getters.getTextMap().workspace_ambient_temp"
          dense
          outlined
          item-text="label"
          item-value="value"
          required
            :rules="objectRules"  :menu-props="{isDark: $store.getters.getColorPalette().isDark}"
        ></v-select>
            </v-col>
            <v-col>
                <v-select  :dark="$store.getters.getColorPalette().isDark" 
            :color="$store.getters.getColorPalette().inputtextColor"
                v-if="!loading"
              v-model="device6"
          :items="deviceOptions3"
          :label="$store.getters.getTextMap().device_ambient_temp"
          dense
          outlined
          item-text="label"
          item-value="value"
          required
            :rules="objectRules"  :menu-props="{dark: $store.getters.getColorPalette().isDark}"
        ></v-select>
            </v-col>
            <v-col>
                <v-select   :dark="$store.getters.getColorPalette().isDark" 
             :color="$store.getters.getColorPalette().inputtextColor"
                v-if="!loading"
              v-model="form.ambient_temp"
          :items="paramOptions6"
          :label="$store.getters.getTextMap().ambient_temp"
          dense
          outlined
          
          item-text="label"
          item-value="value"
          required
            
         :rules="objectRules"
         clearable
        :menu-props="{dark: $store.getters.getColorPalette().isDark}"
        ></v-select>
            </v-col>
            
        </v-row>

        <v-row no-gutters>
            <v-col>
                <v-select  :dark="$store.getters.getColorPalette().isDark" 
           :color="$store.getters.getColorPalette().inputtextColor"
                class="mx-1"
                v-if="!loading"
              v-model="workspace1"
          :items="workspaceOptions"
          :label="$store.getters.getTextMap().workspace"
          dense
          outlined
          item-text="label"
          item-value="value"
          required
             :menu-props="{dark: $store.getters.getColorPalette().isDark}"
        ></v-select>
            </v-col>
        </v-row>
        <v-row no-gutters>
            
            <v-col align-self="center">
                <v-select   :dark="$store.getters.getColorPalette().isDark" 
       :color="$store.getters.getColorPalette().inputtextColor"
                class="mx-1"
                v-if="!loading"
              v-model="device1"
          :items="deviceOptions1"
          :label="$store.getters.getTextMap().device"
          dense
          outlined
          item-text="label"
          item-value="value"
          required
           :menu-props="{dark: $store.getters.getColorPalette().isDark}"
        ></v-select>
            </v-col>
            <v-col>
                <v-select   :dark="$store.getters.getColorPalette().isDark" 
          :color="$store.getters.getColorPalette().inputtextColor"
                class="mx-1"
                v-if="!loading"
              v-model="form.temp_r"
          :items="paramOptions1"
          :label="$store.getters.getTextMap().r_temperature"
          dense
          outlined
          filled
          item-text="label"
          item-value="value"
          required
            
         :rules="objectRules"
         clearable
        :menu-props="{dark: $store.getters.getColorPalette().isDark}"
        ></v-select>
            </v-col>
            
        </v-row>

        <v-row no-gutters>
            <v-col>
                <v-select  :dark="$store.getters.getColorPalette().isDark" 
            :color="$store.getters.getColorPalette().inputtextColor"
                class="mx-1"
                v-if="!loading"
              v-model="device2"
          :items="deviceOptions1"
          :label="$store.getters.getTextMap().device"
          dense
          outlined
          
          item-text="label"
          item-value="value"
          required
         :menu-props="{dark: $store.getters.getColorPalette().isDark}"
        ></v-select>
            </v-col>
            <v-col>
                <v-select  :dark="$store.getters.getColorPalette().isDark" 
          :color="$store.getters.getColorPalette().inputtextColor"
                class="mx-1"
                v-if="!loading"
              v-model="form.temp_y"
          :items="paramOptions2"
          :label="$store.getters.getTextMap().y_temperature"
          dense
          outlined
          filled
          item-text="label"
          item-value="value"
          required
            
         :rules="objectRules"
         clearable
         :menu-props="{dark: $store.getters.getColorPalette().isDark}"
        ></v-select>
            </v-col>
            
        </v-row>
        
        <v-row no-gutters>
            <v-col>
                <v-select :dark="$store.getters.getColorPalette().isDark" 
            :color="$store.getters.getColorPalette().inputtextColor"
                class="mx-1"
                v-if="!loading"
              v-model="device3"
          :items="deviceOptions1"
          :label="$store.getters.getTextMap().device"
          dense
          outlined
          
          item-text="label"
          item-value="value"
          required
            :menu-props="{dark: $store.getters.getColorPalette().isDark}"
        ></v-select>
            </v-col>
            <v-col>
                <v-select  :dark="$store.getters.getColorPalette().isDark" 
        :color="$store.getters.getColorPalette().inputtextColor"
                class="mx-1"
                v-if="!loading"
              v-model="form.temp_b"
          :items="paramOptions3"
          :label="$store.getters.getTextMap().b_temperature"
          dense
          outlined
          filled
          item-text="label"
          item-value="value"
          required
            
         :rules="objectRules"
         clearable
          :menu-props="{dark: $store.getters.getColorPalette().isDark}">
          </v-select>
            </v-col>
            
        </v-row>
        
        <v-row no-gutters>
            <v-col>
                <v-select  :dark="$store.getters.getColorPalette().isDark" 
            :color="$store.getters.getColorPalette().inputtextColor"
                class="mx-1"
                v-if="!loading"
              v-model="device4"
          :items="deviceOptions1"
          :label="$store.getters.getTextMap().device"
          dense
          outlined
          
          
          item-text="label"
          item-value="value"
          required
             :menu-props="{dark: $store.getters.getColorPalette().isDark}"
        ></v-select>
            </v-col>
            <v-col>
                <v-select :dark="$store.getters.getColorPalette().isDark" 
           :color="$store.getters.getColorPalette().inputtextColor"
                class="mx-1"
                v-if="!loading"
              v-model="form.temp_n"
          :items="paramOptions4"
          :label="$store.getters.getTextMap().n_temperature"
          dense
          outlined
          filled
          item-text="label"
          item-value="value"
          required
            
         
         clearable
         :menu-props="{dark: $store.getters.getColorPalette().isDark}"
        ></v-select>
            </v-col>
            
        </v-row>
        <v-divider></v-divider>
        <v-row no-gutters>
            <v-col>
                <strong  :style="{'color':$store.getters.getColorPalette().accentCode}">{{ $store.getters.getTextMap().current_parameters }}</strong>
            </v-col>
        </v-row>

        <v-row no-gutters>
            <v-col align-self="center">
                <v-text-field  :dark="$store.getters.getColorPalette().isDark" 
            :color="$store.getters.getColorPalette().inputtextColor"
                class="mx-1"
                        :loading="loading"
                        v-model="form.rated_current"
                        :counter="6"
                        :rules="numberRules"
                        :label="$store.getters.getTextMap().rated_current"
                        required
                        filled
                ></v-text-field>
                <v-skeleton-loader
                    v-if="loading"
                    class="mx-auto"
                    type="text"
                ></v-skeleton-loader>
            </v-col>
            <v-col align-self="center">
                <v-text-field  :dark="$store.getters.getColorPalette().isDark" 
           :color="$store.getters.getColorPalette().inputtextColor"
                class="mx-1"
                        :loading="loading"
                        v-model="form.no_load_current"
                        :counter="6"
                        :rules="numberRules"
                        :label="$store.getters.getTextMap().no_load_current"
                        required
                ></v-text-field>
                <v-skeleton-loader
                    v-if="loading"
                    class="mx-auto"
                    type="text"
                ></v-skeleton-loader>
            </v-col>
        </v-row>


        <v-row no-gutters>
            <v-col align-self="center">
                <v-select  :dark="$store.getters.getColorPalette().isDark" 
            :color="$store.getters.getColorPalette().inputtextColor"
                class="mx-1"
                v-if="!loading"
              v-model="workspace2"
          :items="workspaceOptions"
          :label="$store.getters.getTextMap().workspace"
          dense
          outlined
          item-text="label"
          item-value="value"
          required
           :menu-props="{dark: $store.getters.getColorPalette().isDark}"
        ></v-select>
            </v-col>
            <v-col align-self="center">
                <v-select  :dark="$store.getters.getColorPalette().isDark" 
           :color="$store.getters.getColorPalette().inputtextColor"
                class="mx-1"
                v-if="!loading"
              v-model="device5"
          :items="deviceOptions2"
          :label="$store.getters.getTextMap().device"
          dense
          outlined
          
          
          item-text="label"
          item-value="value"
          required
          :menu-props="{dark: $store.getters.getColorPalette().isDark}"
        ></v-select>
            </v-col>
        </v-row>

        
        <v-row no-gutters>
            
            <v-col>
                <v-select  :dark="$store.getters.getColorPalette().isDark" 
        :color="$store.getters.getColorPalette().inputtextColor"
                class="mx-1"
                v-if="!loading"
              v-model="form.current_r"
          :items="paramOptions5"
          :label="$store.getters.getTextMap().r_current"
          dense
          outlined
          filled
          item-text="label"
          item-value="value"
          required
            
         :rules="objectRules"
         clearable
         :menu-props="{dark: $store.getters.getColorPalette().isDark}"
        ></v-select>
            </v-col>
            <v-col>
                <v-select  :dark="$store.getters.getColorPalette().isDark" 
           :color="$store.getters.getColorPalette().inputtextColor"
                class="mx-1"
                v-if="!loading"
              v-model="form.current_y"
          :items="paramOptions5"
          :label="$store.getters.getTextMap().y_current"
          dense
          outlined
          
          item-text="label"
          item-value="value"
          required
            
         :rules="objectRules"
         clearable
         :menu-props="{dark: $store.getters.getColorPalette().isDark}"
        ></v-select>
            </v-col>
            <v-col>
                <v-select :dark="$store.getters.getColorPalette().isDark" 
            :color="$store.getters.getColorPalette().inputtextColor"
                class="mx-1"
                v-if="!loading"
              v-model="form.current_b"
          :items="paramOptions5"
          :label="$store.getters.getTextMap().b_current"
          dense
          outlined
          
          item-text="label"
          item-value="value"
          required
            
         :rules="objectRules"
         clearable
        :menu-props="{dark: $store.getters.getColorPalette().isDark}"
        ></v-select>
            </v-col>
            <v-col>
                <v-select  :dark="$store.getters.getColorPalette().isDark" 
            :color="$store.getters.getColorPalette().inputtextColor"
                v-if="!loading"
              v-model="form.current_n"
          :items="paramOptions5"
          :label="$store.getters.getTextMap().n_current"
          dense
          outlined
          
          item-text="label"
          item-value="value"
          required
            
         
         clearable
        :menu-props="{dark: $store.getters.getColorPalette().isDark}"
        ></v-select>
            </v-col>
            
        </v-row>

        <v-row>
            <v-col>
                <v-select  :dark="$store.getters.getColorPalette().isDark" 
            :color="$store.getters.getColorPalette().inputtextColor"
                v-if="!loading"
              v-model="form.notification_group"
          :items="notificationGroups"
          :label="$store.getters.getTextMap().notification_group"
          dense
          outlined
          
          item-text="label"
          item-value="value"
          required
            
         
         clearable
         :menu-props="{dark: $store.getters.getColorPalette().isDark}"
        ></v-select>
            </v-col>
        </v-row>


        <v-row>
            <v-col align="center">
                        <v-skeleton-loader
                            v-if="loading"
                            class="mx-auto"
                            type="text"
                        ></v-skeleton-loader>
                    <v-btn
                    v-if="!loading"
                     :color="$store.getters.getColorPalette().submitbtnColor"
                       
                        @click="onSubmit"
                    >
                       {{$store.getters.getTextMap().submit}}
                    </v-btn>
                </v-col>
                <v-col align="center">
                    <v-skeleton-loader
                            v-if="loading"
                            class="mx-auto"
                            type="text"
                        ></v-skeleton-loader>
                    <v-btn
                    v-if="!loading"
                       :color="$store.getters.getColorPalette().cancelbtnColor"
                         :dark="$store.getters.getColorPalette().isDark" 
                        @click="close"
                    >
                    {{$store.getters.getTextMap().cancel}}
                    </v-btn>
                </v-col>
        </v-row>
    </v-form>
    
    </v-container>
            
</template>
<script>
import InfoAlert from '@/components/InfoAlert'
import axios from 'axios'
import FormRules from '@/utillities/FormRules'
export default {
    name:'CreateBusbarTempModule',
    props:['busbarTempModule'],
    components:{
        InfoAlert
    },
    mounted(){
        if(this.busbarTempModule && this.busbarTempModule.module_id){
            this.form=Object.assign({},this.busbarTempModule)
            this.edit=true
            this.api='updateBusbarTempModule'
        }else{
            this.edit=false
            this.api='createBusbarTempModule'
        }
    },
    data(){
        return {
            edit:false,
            loading:false,
            showDismissibleAlert:false,
            info:'',
            valid:false,
            workspace1:null,
            workspace2:null,
            workspace3:null,
            device1:null,
            device2:null,
            device3:null,
            device4:null,
            device5:null,
            device6:null,
            device7:null,
            device8:null,
            device9:null,
            api:'createBusbarTempModule',
            form:{
                name:null,
                module_type:'busbar_temp',
                
            },
            nameRules: [
                v => !!v || 'Name is required',
                v => /\S+/.test(v) || 'Name is required',
                v => (v && v.length <= 25 && v.length > 0) || 'Name must be less than 25 characters',
            ],
            msgRules: [
                v => !!v || 'Description is required',
                v => (v && v.length <= 55 && v.length > 0) || 'Description must be less than 55 characters',
            ],
            selectRules:[
                v=> (v!=null) || 'Required'
            ],
            typeOptions:[
                {label:'Text',value:'text'},
                {label:'Parameter',value:'param'}
            ],
            objectRules:FormRules.objectRules,
            numberRules:FormRules.numberRulesDynamic(6)
        }
    },
    computed:{
        notificationGroups(){
            let op=[]
            for(let i of this.$store.state.notificationGroups){
                op.push({
                    label:i.name,
                    value:i.group_id
                })
            }
            return op
        },
        hubOptions(){
            let op=[]
            for(let i of this.$store.state.hubs){
                op.push({
                    label:i.hub_id,
                    value:i.hub_id
                })
            }
            return op
        },
        workspaceOptions(){
            let op=[]
          if(this.form.hub_id){
              let g=this.$store.getters.getWorkspacesByHubId(this.form.hub_id)
              for(let i of g){
                  //op.push({value:i, text: i.name })
                  op.push( {value:i, label: i.name })
                }
          }
          return op
        },
        deviceOptions1(){
             
            let op=[]
          
          if(this.workspace1 && this.workspace1.workspace_id ){
              let g=this.$store.getters.getDevicesByWorkspaceId(this.workspace1.workspace_id)
              for(let i of g){
                  
                  if(i && i.name ){
                    
                    op.push({value:i, label: i.name })
                  }else{
                    op.push({value:i, label: i.device_id })
                  }
                  
              
          }
          }
          return op
        },
        deviceOptions2(){
             
            let op=[]
          
          if(this.workspace2 && this.workspace2.workspace_id ){
              let g=this.$store.getters.getDevicesByWorkspaceId(this.workspace2.workspace_id)
              for(let i of g){
                  
                  if(i && i.name ){
                    
                    op.push({value:i, label: i.name })
                  }else{
                    op.push({value:i, label: i.device_id })
                  }
                  
              
          }
          }
          return op
        },
        deviceOptions3(){
             
            let op=[]
          
          if(this.workspace3 && this.workspace3.workspace_id ){
              let g=this.$store.getters.getDevicesByWorkspaceId(this.workspace3.workspace_id)
              for(let i of g){
                  
                  if(i && i.name ){
                    
                    op.push({value:i, label: i.name })
                  }else{
                    op.push({value:i, label: i.device_id })
                  }
                  
              
          }
          }
          return op
        },
        paramOptions1(){
             
            let op=[]
          
          if(this.device1 && this.device1.device_id ){
              let g=this.$store.getters.getParametersByDeviceID(this.device1.device_id)
              for(let i of g){
                  
                  if(i && i.name ){
                    
                    op.push({value:i.param_id, label: i.name })
                  }else{
                    op.push({value:i.param_id, label: i.param_id })
                  }
                  
              
          }
          }
          return op
        },
        paramOptions2(){
             
            let op=[]
          
          if(this.device2 && this.device2.device_id ){
              let g=this.$store.getters.getParametersByDeviceID(this.device2.device_id)
              for(let i of g){
                  
                  if(i && i.name ){
                    
                    op.push({value:i.param_id, label: i.name })
                  }else{
                    op.push({value:i.param_id, label: i.param_id })
                  }
                  
              
          }
          }
          return op
        },
        paramOptions3(){
             
            let op=[]
          
          if(this.device3 && this.device3.device_id ){
              let g=this.$store.getters.getParametersByDeviceID(this.device3.device_id)
              for(let i of g){
                  
                  if(i && i.name ){
                    
                    op.push({value:i.param_id, label: i.name })
                  }else{
                    op.push({value:i.param_id, label: i.param_id })
                  }
                  
              
          }
          }
          return op
        },
        paramOptions4(){
             
            let op=[]
          
          if(this.device4 && this.device4.device_id ){
              let g=this.$store.getters.getParametersByDeviceID(this.device4.device_id)
              for(let i of g){
                  
                  if(i && i.name ){
                    
                    op.push({value:i.param_id, label: i.name })
                  }else{
                    op.push({value:i.param_id, label: i.param_id })
                  }
                  
              
          }
          }
          return op
        },
        paramOptions5(){
             
            let op=[]
          
          if(this.device5 && this.device5.device_id ){
              let g=this.$store.getters.getParametersByDeviceID(this.device5.device_id)
              for(let i of g){
                  
                  if(i && i.name ){
                    
                    op.push({value:i.param_id, label: i.name })
                  }else{
                    op.push({value:i.param_id, label: i.param_id })
                  }
                  
              
          }
          }
          return op
        },
        paramOptions6(){
             
            let op=[]
          
          if(this.device6 && this.device6.device_id ){
              let g=this.$store.getters.getParametersByDeviceID(this.device6.device_id)
              for(let i of g){
                  
                  if(i && i.name ){
                    
                    op.push({value:i.param_id, label: i.name })
                  }else{
                    op.push({value:i.param_id, label: i.param_id })
                  }
                  
              
          }
          }
          return op
        },
    },
    methods:{
        onSubmit(){
            this.$refs.moduleForm.validate()
            if(this.valid){
                 this.loading=true
                //let d=Object.assign({}, this.form)
                //console.log(d)
                //d['data']={headers:[],params:[]}
                axios.post(this.$store.state.api+this.api,this.form,{headers: {
                    Authorization: 'Bearer '+ this.$store.state.jwt
                    }})
                    .then(response=>{
                        if(response.data.status=='success'){
                            this.loading=false
                            this.$store.dispatch('refreshModules')
                            //this.$refs.moduleForm.reset()
                            this.close()
                        }else{
                            this.loading=false
                            this.info=response.data.msg
                            this.showDismissibleAlert=true
                        }
                    }).catch(err=>{
                        this.loading=false
                        this.info=err
                        this.showDismissibleAlert=true
                        
                    })
            }
        },
        close(){
            this.$emit('close')
        }
    }
}
</script>