<template>

    <v-container fluid>

       <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />

        <v-row no-gutters v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess']) ">

            <v-col align-self="center" align="right">

                <span><v-icon color="blue" small @click="showEdit=!showEdit">mdi-pencil</v-icon></span>

                <span><DeleteConfirmation  v-on:confirm="deleteModule()"  title="Delete Confirmation"  description="Are you sure you want to delete this?"><v-icon color="red" small >mdi-delete</v-icon></DeleteConfirmation ></span>

            </v-col>

        </v-row>

        <v-row v-if="showEdit">

            <v-col cols="12">

               <CreateESGModule :esgModule="esgModule" v-on:close="showEdit=!showEdit"/>

            </v-col>

        </v-row>

        <v-row v-if="$store.state.user && $store.state.user.organisation_id==='26c7ea9e-8d15-4022-bd9e-e4166a6078e5'">
            <v-col>
                <EmissionTable />
            </v-col>
        </v-row>

        <v-row dense v-if="esgModule">

            <v-col cols="4">

                <ESGModulePieChart :module="esgModule"/>

            </v-col>

            <v-col  cols="8">

                <ESGModuleBarGraph :module="esgModule"/>

            </v-col>

        </v-row>

        <v-row dense v-if="esgModule">

            <v-col cols="4">

                <ESGModuleScopeWiseTable :module="esgModule"/>

            </v-col>

            <v-col cols="8">

                <ESGModuleMachineWiseTable :module="esgModule"/>

            </v-col>

        </v-row>

    </v-container>

</template>




<script>

import InfoAlert from '@/components/InfoAlert'

import DeleteConfirmation from '@/components/DeleteConfirmation'

import CreateESGModule from '@/components/crud_components/modules/CreateESGModule'

import axios from 'axios'

import ESGModulePieChart from '@/components/panels/modules/esg/ESGModulePieChart'

import ESGModuleBarGraph from '@/components/panels/modules/esg/ESGModuleBarGraph'

import ESGModuleScopeWiseTable from '@/components/panels/modules/esg/ESGModuleScopeWiseTable'

import ESGModuleMachineWiseTable from '@/components/panels/modules/esg/ESGModuleMachineWiseTable'

import EmissionTable from '@/components/tables/esg/EmissionTable'


export default {

   name:'ESGModulePage',

   components:{
    InfoAlert,
    DeleteConfirmation,
    CreateESGModule,
    ESGModulePieChart,
    ESGModuleBarGraph,
    ESGModuleScopeWiseTable,
    ESGModuleMachineWiseTable,
    EmissionTable
},

   mounted(){

       this.module_id=this.$route.params.id

       this.getModule()

       console.log(this.esgModule)

   },

   data(){

       return {

            showDialog:false,

           loading:false,

           info:'',

           search:null,

           showDismissibleAlert:false,

           showEdit:false,

           esgModule:null,

           module_id:null,

       }

   },

   methods:{

       getModule(){

       if(this.module_id){

           this.loading=true

           axios.post(this.$store.state.api+'getESGModuleByID',{module_id:this.module_id},{headers: {

                   Authorization: 'Bearer '+ this.$store.state.jwt

                   }})

                   .then(response=>{

                       if(response.data.status=='success'){

                           this.edit=false

                           this.esgModule=response.data.module

                        //    console.log(this.esgModule)

                       }else{

                           this.info=response.data.msg

                           this.showDismissibleAlert=true

                       }

                       this.loading=false

                   }).catch(err=>{

                       this.loading=false

                       this.info=err

                       this.showDismissibleAlert=true

                   })




           }

       },

       deleteModule(){

           if(this.module_id){

               this.loading=true

               let payload={

                   module_id:this.module_id

               }

               axios.post(this.$store.state.api+'deleteEntureModule',payload,{headers: {

                       Authorization: 'Bearer '+ this.$store.state.jwt

                      }})

                       .then(response=>{

                           if(response.data.status=='success'){

                               this.loading=false

                               this.edit=false

                               this.$store.dispatch('refreshModules')

                               

                           }else{

                               this.loading=false

                               this.info=response.data.msg

                               this.showDismissibleAlert=true

                           }

                       }).catch(err=>{

                           this.loading=false

                           this.info=err

                           this.showDismissibleAlert=true

                           

                       })

                   }

       },

        cancel(){

         this.showDialog=false

       },

       confirm(){

         this.showDialog=false

       }

   },

   watch:{

       '$route'() {

                 this.module_id = this.$route.params.id

                 this.getModule()

   },

   }

}

</script>




<style scoped>

.limit_height {

   max-height:400px;

}

.usage {

   color: #FDD835;

   font-size: 300%;

   font-weight: bold;

}

.unit{

   font-size: 0.6em;

}

</style>