<template>
    <v-container>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-form
                ref="moduleForm"
                v-model="valid"          
            >
            <v-row>
                <v-col>
                <v-text-field  :dark="$store.getters.getColorPalette().isDark" 
                 color="cyan lighten-1"
                            :loading="loading"
                            v-model="form.name"
                            :counter="25"
                            :rules="nameRules"
                            :label="$store.getters.getTextMap().name"
                            required
                            
                        ></v-text-field>
                <v-skeleton-loader
            v-if="loading"
          class="mx-auto"
          type="text"
        ></v-skeleton-loader>
            </v-col>
                <v-col>
                <v-text-field  :dark="$store.getters.getColorPalette().isDark" 
                 color="cyan lighten-1"
                            :loading="loading"
                            v-model="form.msg"
                            :counter="55"
                            :rules="msgRules"
                            :label="$store.getters.getTextMap().description"
                            required
                        ></v-text-field>
                <v-skeleton-loader
            v-if="loading"
          class="mx-auto"
          type="text"
        ></v-skeleton-loader>
            </v-col>
            </v-row>
            <v-row v-if="edit">
            <v-col align="center">
                        <v-skeleton-loader
                            v-if="loading"
                            class="mx-auto"
                            type="text"
                        ></v-skeleton-loader>
                    <v-btn
                    v-if="!loading"
                     :color="$store.getters.getColorPalette().submitbtnColor"
                      
                        @click="onSubmit"
                    >
                     {{$store.getters.getTextMap().submit}}
                    </v-btn>
                </v-col>
                <v-col align="center">
                    <v-skeleton-loader
                            v-if="loading"
                            class="mx-auto"
                            type="text"
                        ></v-skeleton-loader>
                    <v-btn
                    v-if="!loading"
                        color="grey darken-3"
                        :dark="$store.getters.getColorPalette().isDark" 
                        @click="close"
                    >
                    {{$store.getters.getTextMap().cancel}}
                    </v-btn>
                </v-col>
        </v-row>
        <v-card  :dark="$store.getters.getColorPalette().isDark"  :color="$store.getters.getColorPalette().background2ColorCode" style="padding:20px; margin-top:20px">
            <v-row no-gutters>
                <v-col>
                    <v-select  :dark="$store.getters.getColorPalette().isDark" 
                 color="cyan lighten-1"
                        class="mx-1"
                        v-if="!loading"
                        v-model="workspace"
                        :items="workspaceOptions"
                        :label="$store.getters.getTextMap().workspace"
                        dense
                        outlined
                        item-text="label"
                        item-value="value"
                    ></v-select>
            </v-col>
                <v-col>
                    <v-select  :dark="$store.getters.getColorPalette().isDark" 
                 color="cyan lighten-1"
                    class="mx-1"
                    v-if="!loading"
                    v-model="machine"
                    :items="machineOptions"
                    :label="$store.getters.getTextMap().machine"
                    outlined
                    dense
                    item-text="label"
                    item-value="value"
                    required
                    ></v-select>
                </v-col>
                <v-col>
                    <v-select :dark="$store.getters.getColorPalette().isDark" 
                 color="cyan lighten-1"
                    class="mx-1"
                    v-if="!loading"
                    v-model="machine_type"
                    :items="machineTypeOptions"
                    :label="$store.getters.getTextMap().energy_source"
                    outlined
                    dense
                    item-text="label"
                    item-value="value"
                    required
                    ></v-select>
                </v-col>
                <v-col>
                    <v-select  :dark="$store.getters.getColorPalette().isDark" 
                 color="cyan lighten-1"
                    class="mx-1"
                    v-if="!loading"
                    v-model="scope"
                    :items="scopeOptions"
                    :label="$store.getters.getTextMap().scope"
                    outlined
                    dense
                    item-text="label"
                    item-value="value"
                    required
                    ></v-select>
                </v-col>
                <v-col v-if="edit">
                    <v-btn style="margin-bottom:10px;" v-if="!loading"  :color="$store.getters.getColorPalette().submitbtnColor" text @click="addMachineToList">
                        {{ $store.getters.getTextMap().add_machine }}
                    </v-btn>
                </v-col>
                <v-col v-if="!edit">
                    <v-btn style="margin-bottom:10px;" v-if="!loading"  :color="$store.getters.getColorPalette().submitbtnColor" text @click="addMachineToListwhileCreate">
                        {{ $store.getters.getTextMap().add_machine }}
                    </v-btn>
                </v-col>
            </v-row>
            <v-row>
                    <v-col>
                    <v-card :dark="$store.getters.getColorPalette().isDark" :color="'transparent'" flat>
                        <v-row v-if="!loading && machineDetailsList " style="margin-top:20px;">
                            <v-col cols="12">
                                <v-simple-table height="200px" :style="{background: 'transparent'}" >
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <th class="text-left">
                                                    <strong> {{ $store.getters.getTextMap().machine }}</strong>
                                                </th>
                                                <th class="text-left">
                                                    <strong> {{ $store.getters.getTextMap().energy_source }}</strong>
                                                </th>
                                                <th class="text-left">
                                                    <strong> {{ $store.getters.getTextMap().scope }}</strong>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                            :key="item.machine_id" v-for="item in machineDetailsList"
                                            >
                                            <td>{{ item.machine_name }}</td>
                                            <td>{{ item.machine_type}}</td>
                                            <td>{{ item.scope}}</td>
                                            
                                            <td>
                                         <DeleteConfirmation v-on:confirm="removeMachineFromList(item)"  title="Delete Confirmation"  description="Are you sure you want to delete this?">    
                                         <v-icon 
                                         small 
                                         color="red" >
                                         mdi-delete
                                         </v-icon>
                                         </DeleteConfirmation>
                                            </td>
                                            </tr>
                                        </tbody>
                                    </template>
                            </v-simple-table>
                        </v-col>
                    </v-row>
                </v-card>   

            </v-col>
                </v-row>
            </v-card>
            <v-row v-if="!edit" style="margin-top: 2%;">
            <v-col align="center">
                        <v-skeleton-loader
                            v-if="loading"
                            class="mx-auto"
                            type="text"
                        ></v-skeleton-loader>
                    <v-btn
                    v-if="!loading"
                     :color="$store.getters.getColorPalette().submitbtnColor"
                      
                        @click="onSubmit"
                    >
                    {{ $store.getters.getTextMap().submit }}
                    </v-btn>
                </v-col>
                <v-col align="center">
                    <v-skeleton-loader
                            v-if="loading"
                            class="mx-auto"
                            type="text"
                        ></v-skeleton-loader>
                    <v-btn
                    v-if="!loading"
                        color="grey darken-3"
                      :dark="$store.getters.getColorPalette().isDark"
                        @click="close"
                    >
                    {{ $store.getters.getTextMap().cancel }}
                    </v-btn>
                </v-col>
        </v-row>
    </v-form>
    </v-container>
    </template>
    
    <script>
    import axios from 'axios'
    import InfoAlert from '@/components/InfoAlert'
    import FormRules from '@/utillities/FormRules';
    import DeleteConfirmation from '@/components/DeleteConfirmation'
    export default {
        name:'CreateESGModule',
        props:['esgModule'],
        components:{
            InfoAlert,
            DeleteConfirmation
        },
        mounted(){
            if(this.esgModule && this.esgModule[0].module_id){
                this.form=Object.assign({},this.esgModule[0])
                for(let i of this.esgModule){
                    this.machineDetailsList.push({machine_id:i.machine_id,machine_name:i.machine_name,machine_type:i.machine_type,scope:i.scope})
                }
                this.edit=true
                this.api='updateEsgModule'
            }else{
                this.edit=false
                this.api='createEsgModule'
            }
        },
        data(){
            return {
                loading:false,
                info:'',
                edit:false,
                showDismissibleAlert:false,
                valid:false,
                api:'createEsgModule',
                machineDetailsList:[],
                machine_type:null,
                scope:2,
                workspace:null,
                machine:null,
                machineTypeOptions:[
                {label:'Solar', value:'solar'},
                {label:'DG', value:'dg'},
                {label:'EB', value:'eb'},
                ],
                scopeOptions:[
                {label:'Scope 1', value:'1'},
                {label:'Scope 2', value:'2'},
                ],
                machine_form:{},
                form:{
                    name:null,
                    module_type:'esg',
                    
                },
                nameRules: [
                    v => !!v || 'Name is required',
                    v => /\S+/.test(v) || 'Name is required',
                    v => (v && v.length <= 25 && v.length > 0) || 'Name must be less than 25 characters',
                ],
                msgRules: [
                    v => !!v || 'Description is required',
                    v => (v && v.length <= 55 && v.length > 0) || 'Description must be less than 55 characters',
                ],
                selectRules:[
                    v=> (v!=null) || 'Required'
                ],
                typeOptions:[
                    {label:'Text',value:'text'},
                    {label:'Parameter',value:'param'}
                ],
                machineOptions:[],
                paramOptions:[],
                rules:FormRules.rules,
                objectRules:FormRules.objectRules,
                numberRules:FormRules.numberRulesDynamic(6)
            }
        },
        computed:{
            workspaceOptions(){
                let op=[]
                let g=this.$store.state.workspaces
                for(let i of g){
                    op.push( {value:i.workspace_id, label: i.name })
                }
                return op
                },
        },
        methods:{
            getMachineList(){
                let op=[]
                if(this.workspace){
                    let payload={
                        workspace_id:this.workspace
                    }
                    //console.log(payload)
                    this.loading = true;
          axios.post(this.$store.state.api + "getMachinesByWorkspace", payload,{headers: {
                Authorization: 'Bearer '+ this.$store.state.jwt
                }})
                    .then(response => {
                    if (response.data.status == "success") {
                        
                        
                        //console.log(response.data);
                        
                        let machines=response.data.machines
                    //this.loading = false;
                    for(let i of machines){
                      
                      if(i && i.name){
                        op.push({value:{machine_id:i.machine_id,machine_name:i.name}, label: i.name })
                      }else{
                        op.push({value:{machine_id:i.machine_id,machine_name:i.machine_id}, label: i.machine_id })
                      }
                    }
                        //console.log(op)
                    } else {
                        this.info = response.data.msg;
                        this.showDismissibleAlert = true;
                    }
                    //this.onReset()
                    this.loading = false;
                    })
                    .catch(error => {
                    this.info = error;
                    this.showDismissibleAlert = true;
                    this.loading = false;
                    });
                }
                this.machineOptions=op
            },
            addMachineToListwhileCreate(){
                console.log(this.machineDetailsList)
                this.machineDetailsList.push({machine_id:this.machine.machine_id,machine_name:this.machine.machine_name,machine_type:this.machine_type,scope:this.scope})
            },
            addMachineToList(){
                this.machine_form.machine_id=this.machine.machine_id
                this.machine_form.machine_type=this.machine_type
                this.machine_form.scope=this.scope
                this.machine_form.module_id = this.esgModule[0].module_id
                axios.post(this.$store.state.api+'addMachineToEsgModule',this.machine_form,{headers: {
                        Authorization: 'Bearer '+ this.$store.state.jwt
                        }})
                        .then(response=>{
                            if(response.data.status=='success'){
                                this.loading=false
                                this.machineDetailsList.push({machine_id:this.machine.machine_id,machine_name:this.machine.machine_name,machine_type:this.machine_type,scope:this.scope})
                                this.$store.dispatch('refreshModules')
                                //this.$refs.moduleForm.reset()
                                // this.close()
                            }else{
                                this.loading=false
                                this.info=response.data.msg
                                this.showDismissibleAlert=true
                            }
                        }).catch(err=>{
                            this.loading=false
                            this.info=err
                            this.showDismissibleAlert=true
                            
                        })
            },
            removeMachineFromList(m){
                this.machineDetailsList=this.machineDetailsList.filter(x=>x.machine_id!=m.machine_id)
                this.machine_form.machine_id=m.machine_id
                this.machine_form.module_id = this.esgModule[0].module_id
                axios.post(this.$store.state.api+'removeMachineFromEsgModule',this.machine_form,{headers: {
                        Authorization: 'Bearer '+ this.$store.state.jwt
                        }})
                        .then(response=>{
                            if(response.data.status=='success'){
                                this.loading=false
                                this.$store.dispatch('refreshModules')
                                //this.$refs.moduleForm.reset()
                                // this.close()
                            }else{
                                this.loading=false
                                this.info=response.data.msg
                                this.showDismissibleAlert=true
                            }
                        }).catch(err=>{
                            this.loading=false
                            this.info=err
                            this.showDismissibleAlert=true
                            
                        })
            },
            onSubmit(){
                this.$refs.moduleForm.validate()
                if(this.valid){
                     this.loading=true
                    //let d=Object.assign({}, this.form)
                    //console.log(d)
                    //d['data']={headers:[],params:[]}
                    if(!this.edit){
                        // this.form.machine_id = this.machine.machine_id
                        // this.form.scope = this.scope
                        // this.form.machine_type = this.machine_type
                        this.form.machine_details = this.machineDetailsList
                        // this.machineDetailsList.push({machine_id:this.machine.machine_id,machine_name:this.machine.machine_name,machine_type:this.machine_type})
                    }
                    axios.post(this.$store.state.api+this.api,this.form,{headers: {
                        Authorization: 'Bearer '+ this.$store.state.jwt
                        }})
                        .then(response=>{
                            if(response.data.status=='success'){
                                this.loading=false
                                this.$store.dispatch('refreshModules')
                                //this.$refs.moduleForm.reset()
                                this.close()
                            }else{
                                this.loading=false
                                this.info=response.data.msg
                                this.showDismissibleAlert=true
                            }
                        }).catch(err=>{
                            this.loading=false
                            this.info=err
                            this.showDismissibleAlert=true
                            
                        })
                }
            },
            close(){
                this.$emit('close')
            }
        },
        watch:{
            workspace(){
                this.getMachineList()
            },
        }
    }
    </script>