<template>
    <v-container fluid >  
        <div :style="{'font-size':'150%','color':color}" class="usage"><span>{{ value }}</span> <span class="unit">{{ unit }}</span></div>
    </v-container>
</template>

<script>


export default {
    name:'SingleValueDisplayCard',
    props:['value','unit'],
    components:{
    },
    data(){
        return {
            graphData:{},
            week:{},
            color:this.$store.getters.getColorPalette().accentCode,
            font_size:'150%',
            
            usage:0,
            z_axis_data:null,
            week_x_axis:[],
            objectRules:[
                v => !!v || 'Required',
            ],
        }
    },
    mounted(){
       
        
    },
    methods:{
        
    },
    watch:{
        
    }
}
</script>
<style scoped>
.limit_height {
    max-height:400px;
}
.usage {
    color: #FDD835;
    font-size: 300%;
    font-weight: bold;
}
.unit{
    font-size: 0.6em;
}
</style>