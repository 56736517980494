<template>
    <v-container fluid>
        <!-- <v-card flat :dark="$store.getters.getColorPalette().isDark"  :color="$store.getters.getColorPalette().background2ColorCode"> -->
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
        <!-- <v-row v-if="showPie===true" ><v-col ><div  v-if="this.module" ref="vis_pie"></div></v-col></v-row>
        <v-row v-if="showPie===false"><v-col><div  v-if="this.module" ref="vis_scope"></div></v-col></v-row> -->
        <v-row v-if="showDialog">
          <v-col>
            <v-dialog
              v-model="showDialog"
              fullscreen
              transition="dialog-bottom-transition"
              >
                <ESGScopeDetails :machineDetailList="machineDetailList" :fromScopeCard="fromScopeCard" v-on:close="close()"/>
            </v-dialog>
          </v-col>
        </v-row>
        <v-row>
      <v-col>
        <v-card :style="{'border-radius':'5px','border':'1px solid'+$store.getters.getColorPalette().panelBorderColorCode}" @click="showDetails(1,true)" class="mb-4" flat :dark="$store.getters.getColorPalette().isDark"  :color="$store.getters.getColorPalette().background2ColorCode">
          <v-card-title  :style="{background: $store.getters.getColorPalette().esg_module_card, color:$store.getters.getColorPalette().esg_module_card_text}">
            <v-icon large left :color="$store.getters.getColorPalette().background2ColorCode">mdi-gas-station</v-icon>
            {{$store.getters.getTextMap().scope1_emission}}
            <v-spacer></v-spacer>
            <v-icon large left :color="$store.getters.getColorPalette().background2ColorCode">mdi-solar-power</v-icon>
          </v-card-title>
          <v-spacer></v-spacer>
          
          <v-card-subtitle>
          <v-row dense>
            <v-col cols="8" align-self="center">
                <h2>{{ scope1_data.toFixed(2) }}</h2><h3>tCO₂</h3>
            </v-col>
            <v-spacer></v-spacer>
              <v-col align-item="right">
                <div class="trend-visualization">
                  <v-progress-circular
                    :size="90"
                    :width="20"
                    :value="Math.abs(scope1_data)"
                    :color="scope1_data >= 0 ? '#FF8A80' : '#69F0AE'"
                  >
                  <div class="trend-indicator" :class="{ 'positive': scope1_data >= 0, 'negative': scope1_data < 0 }">
                  <v-icon large>{{ scope1_data >= 0 ? 'mdi-arrow-up' : 'mdi-arrow-down' }}</v-icon>
                </div></v-progress-circular>
                </div>
            </v-col>
            
          </v-row>
          </v-card-subtitle>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card :style="{'border-radius':'5px','border':'1px solid'+$store.getters.getColorPalette().panelBorderColorCode}" @click="showDetails(2,false)" class="mb-4" flat :dark="$store.getters.getColorPalette().isDark"  :color="$store.getters.getColorPalette().background2ColorCode">
          <v-card-title :style="{background: $store.getters.getColorPalette().esg_module_card, color:$store.getters.getColorPalette().esg_module_card_text}">
            <v-icon large left :color="$store.getters.getColorPalette().background2ColorCode">mdi-transmission-tower</v-icon>
            {{$store.getters.getTextMap().scope2_emission}}
          </v-card-title>
          <v-spacer></v-spacer>
          
          <v-card-subtitle>
          <v-row dense>
            <v-col cols="8" align-self="center">
                <h2>{{ scope2_data.toFixed(2) }}</h2> <h3>tCO₂</h3>
            </v-col>
            <v-spacer></v-spacer>
              <v-col align-item="right">
                <div class="trend-visualization">
                  <v-progress-circular
                    :size="90"
                    :width="20"
                    :value="Math.abs(scope2_data)"
                    :color="scope2_data >= 0 ? '#FF8A80' : '#69F0AE'"
                  >
                  <div class="trend-indicator" :class="{ 'positive': scope2_data >= 0, 'negative': scope2_data < 0 }">
                  <v-icon large>{{ scope2_data >= 0 ? 'mdi-arrow-up' : 'mdi-arrow-down' }}</v-icon>
                </div></v-progress-circular>
                </div>
            </v-col>
            
          </v-row>
          </v-card-subtitle>
        </v-card>
      </v-col>
    </v-row>
        <!-- </v-card> -->
    </v-container>
</template>

<script>
import Plotly from 'plotly.js'
import axios from 'axios'
import moment from 'moment'
import InfoAlert from '@/components/InfoAlert'
import ESGScopeDetails from '@/components/modals/modules/esg/ESGScopeDetails'

export default {
    name:'ESGModulePieChart',
    props:['module'],
    components:{
        InfoAlert,
        ESGScopeDetails
    },
    data(){
        return{
            showDialog:false,
            x:0,
            y:0,
            showMenu:false,
            info:'',
            loading:false,
            showDismissibleAlert:false,
            colorCode:this.$store.getters.getColorPalette().gradientColorCodes,
            graphData:{},
            scope1_data:0,
            scope2_data:0,
            showPie:false,
            machineDetailList:[],
            fromScopeCard:false,
            layout:{
                colorway:this.$store.getters.getColorPalette().gradientColorCodes,
                font:{
                    color:this.$store.getters.getColorPalette().accentCode
                },
                plot_bgcolor:this.$store.getters.getColorPalette().background2ColorCode,
                paper_bgcolor:this.$store.getters.getColorPalette().background2ColorCode,
                showlegend:false,
                autosize: true,
                xaxis: {
                    tickangle: -12,
                    zeroline: false,
                    showgrid: true,
                    showticklabels: true,
                }, 
                yaxis: {
                    showticklabels: true
                },
                uniformtext: {
                    "mode": "hide",
                    "minsize": 1
                }
            }
        }
    },
    mounted(){
        if(this.module){
            this.getData()
        }
    },
    methods:{
        show (e) {
        e.preventDefault()
        this.showMenu = false
        this.x = e.clientX
        this.y = e.clientY
        this.$nextTick(() => {
          this.showMenu = true
        })
      },
      createChart(){
        this.graphData['scope1']=this.scope1_data
        this.graphData['scope2']=this.scope2_data
        this.layout['title']= 'Scope 1 v/s Scope 2'
        // let textInfo='label+percent'
        // this.layout['annotations']=[{
        //     font: {
        //         size: 22
        //     },
        //     showarrow: false,
        //     text: 'MTD',
        //     x: 0.5,
        //     y: 1
        // }]
        // var data_temp = [{
        //     type: "waterfall",
        //     orientation: "v",
        //     x:Object.keys(this.graphData),
        //     y:Object.values(this.graphData),
        //     measure: ["relative", "absolute"],
        //     connector: {
        //       line: {
        //         color: this.$store.getters.getColorPalette().gradientColorCodes
        //       }
        //     },
        // }]

        var data_bullet=[
            {
            type: "indicator",
            mode: "number+gauge+delta",
            gauge: { shape: "bullet" ,bar: { color: this.$store.getters.getColorPalette().widgettableHeaderColorCode  }},
            delta: { reference: 0 ,
                decreasing:{
                  color:'green'
                },
                increasing:{
                  color:'red'
                },},
            value: (Object.values(this.graphData))[0],
            domain: { x: [0.25, 1], y: [0.08, 0.25] },
            title: { text: "Scope 1" },
            bar: { color: "black" }
        },
            {
            type: "indicator",
            mode: "number+gauge+delta",
            gauge: { shape: "bullet" ,bar: { color: this.$store.getters.getColorPalette().widgettableHeaderColorCode }},
            delta: { reference: 0 ,                
                decreasing:{
                  color:'green'
                },
                increasing:{
                  color:'red'
                },},
            value: (Object.values(this.graphData))[1],
            domain: {  x: [0.25, 1], y: [0.4, 0.6]  },
            title: { text: "Scope 2" },
            
        }
    ]

        // var data = [{
        //     values: Object.values(this.graphData),
        //     labels: Object.keys(this.graphData),
        //     type:'pie',
        //     text:'mtd',
        //     textposition:'outside',
        //     textinfo: textInfo,
        //     opacity:1,
        //     hole:0.7,
        //     marker:{
        //         line:{
        //             color:this.$store.getters.getColorPalette().accentCode,
        //             width: 1
        //         },
        //     },
        // }]; 


        this.layout.margin= { t: 30, r:20, l: 0, b: 10 }
        // Plotly.newPlot(this.$refs.vis_pie, data, this.layout, {displaylogo: false});
        Plotly.newPlot(this.$refs.vis_scope, data_bullet, this.layout, {displaylogo: false});

      },
      calculateScopeData(scope1=0,scope2=0,machine_type){
        if(machine_type==='solar'){
            this.scope1_data = (-1*scope1) + this.scope1_data
        }else{
            this.scope1_data = scope1 + this.scope1_data
        }
          this.scope2_data = scope2 + this.scope2_data
      },

      getData(){
        this.graphData={}
        let from_date=moment().subtract(1,'days')
        from_date=from_date.startOf('month').format('YYYYMMDD')
        if(this.module){
            this.scope1_data = 0
            this.scope2_data = 0
            for(let i of this.module){
                let payload={
                    module_id:i.module_id,
                    machine_id:i.machine_id,
                    from_date:from_date
                }

                axios.post(this.$store.state.api+'getESGModuleDataBetweenDate',payload,{headers: {
                    Authorization: 'Bearer '+ this.$store.state.jwt
                }}).then(response=>{
                  console.log(response.data)
                    if(response.data.status==='success'){
                        if(i.scope===1){
                            this.calculateScopeData(response.data.data[0]['data'],0,i.machine_type)
                        }else{
                            this.calculateScopeData(0,response.data.data[0]['data'],i.machine_type)
                        }
                    }
                    this.createChart()
                }).catch(err=>{
                    console.error(err)
                    this.loading=false
                })
            }

            
        }
      },
      showDetails(scope,fromScopeCard){
        this.machineDetailList = []
        switch(scope){
            case 1:
                for(let i of this.module){
                  if(i.scope===1){
                    this.machineDetailList.push(i)
                  }
                }
                break;
            case 2:
              for(let i of this.module){
                  if(i.scope===2){
                    this.machineDetailList.push(i)
                  }
                }
                break;
              }
        this.fromScopeCard=fromScopeCard
        this.showDialog = true
      },
      close(){
            this.showDialog = false   
        }
    }
}
</script>

<style scoped>
.limit_height {
    max-height:400px;
}
.trend-visualization {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 110px;
}

.trend-visualization v-progress-circular {
  transition: none !important;
}

h3{
    color:#00E676;
}
</style>