<template>
     <v-container fluid :style="{background: $store.getters.getColorPalette().backgroundColorCode}">
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        
          <v-row no-gutters v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess']) ">
          <v-col align-self="center" align="right">
            <span>
              <v-icon color="blue" small @click="showEdit=!showEdit">
        mdi-pencil
      </v-icon>
            </span>
            <span>
                 <DeleteConfirmation  v-on:confirm="deleteModule()"  title="Delete Confirmation"  description="Are you sure you want to delete this?">
              <v-icon color="red" small >
                mdi-delete
               </v-icon>
                 </DeleteConfirmation >
            </span>
            </v-col>
        </v-row>
         <v-row v-if="showEdit">
            <v-col cols="12">
                <CreatePQModule :pqModule="pqModule" v-on:close="showEdit=!showEdit"/>
                </v-col>
        </v-row>
        
            <v-card class="px-0 mb-1" :color="$store.getters.getColorPalette().backgroundColorCode">
                <v-row no-gutters v-if="pqModule">
            <v-col align-self="center" align="center">
                
                <div :style="{'background-color': $store.getters.getColorPalette().colorTypesCodes['purple']}">
                    <span :style="{'color': $store.getters.getColorPalette().accentCode}">Energy Used Month to Date</span>
                    <SingleParamTDUsage  :style="{'background-color': $store.getters.getColorPalette().colorTypesCodes['purple']}" :param="pqModule.active_energy" type="active_energy" time="mtd" />
                </div>
            </v-col>
            
            <v-col align-self="center" align="center">
                <div :style="{'background-color': $store.getters.getColorPalette().colorTypesCodes['orange']}">
                    <span :style="{'color': $store.getters.getColorPalette().accentCode}">Apparent Energy Used Month to Date</span>
                <SingleParamTDUsage   :style="{'background-color': $store.getters.getColorPalette().colorTypesCodes['orange']}" :param="pqModule.apparent_energy" type="apparent_energy" time="mtd" />
                </div>
            </v-col>
            
            <v-col align-self="center" align="center">
                <div :style="{'background-color': $store.getters.getColorPalette().colorTypesCodes['blue']}">
                    <span :style="{'color': $store.getters.getColorPalette().accentCode}">Voltage Unbalance Duration</span>
                    <SingleValueDisplayCard :value="volt_unbalance_dur" unit="Mins"/>
                </div>
            </v-col>
            </v-row>
        </v-card>
        
        <v-row v-if="pqModule">
            <v-col>
                <v-card  :dark="$store.getters.getColorPalette().isDark" flat >
                <SingleParamUsageHeatMap :param="pqModule.active_energy" label="Energy Consumption Heatmap" />
                </v-card>
            </v-col>
            </v-row>
            <v-row v-if="pqModule">
            <v-col>
                <v-card :dark="$store.getters.getColorPalette().isDark" flat :style="{'border-radius': '5px ','border': '1px solid '+ $store.getters.getColorPalette().cardBorderColor +$store.getters.getColorPalette().background1ColorCode+' , '+$store.getters.getColorPalette().backgroundColorCode+')'}">
                <LatestUsagePanel :params="energyParams" title='kWH vs kVAH' :showGraphType="false"/>
                </v-card>
            </v-col>
        <!--</v-row>
        <v-row v-if="pqModule">
            <v-col>
                <v-card    flat :style="{'border-radius': '15px ','border': '1px solid '+ $store.getters.getColorPalette().cardBorderColor, 'background-image':'linear-gradient(90deg, '+$store.getters.getColorPalette().background1ColorCode+' , '+$store.getters.getColorPalette().backgroundColorCode+')'}">
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12"><div class="center" ref="graph"></div></v-col>
                            </v-row>
                            </v-container>
                </v-card>
            </v-col>
            
            
            </v-row>
            <v-row>-->
                 <v-col>
                <v-card :dark="$store.getters.getColorPalette().isDark" flat >
                <MultiParamLineGraph :params="multiParams" title='MultiParam' :showGraphType="false"/>
                </v-card>
            </v-col>
                </v-row>
            
            <v-row>
                <v-col>
                    <v-data-table
      :headers="headers"
      :items="pqModuleData"
      item-key="date"
      :search="search"
      
    :dark="$store.getters.getColorPalette().isDark"
      :loading="loading"
    loading-text="Loading... Please wait"
    >
    <template v-slot:top>
      <v-toolbar
        flat
      >
        <v-toolbar-title>Power Quality</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>
        
      </v-toolbar>
      
          </template>
          
          <template v-slot:item.tdd_i="{item}">
            <td >{{parseFloat(item.tdd_i).toFixed(3)}}</td>
          </template>

          <template v-slot:item.filename="{ item }">
            <v-btn :dark="$store.getters.getColorPalette().isDark" small text class="switch" :color="$store.getters.getColorPalette().foregroundColorCode" @click="downloadReport(item)">Download</v-btn>
             
    </template>
    </v-data-table>
                </v-col>
            </v-row>
        </v-container>

</template>
<script>
import InfoAlert from '@/components/InfoAlert'
import DeleteConfirmation from '@/components/DeleteConfirmation'
import LatestUsagePanel from '@/components/panels/LatestUsagePanel'
import MultiParamLineGraph from '@/components/panels/MultiParamLineGraph'
import SingleParamUsageHeatMap from '@/components/graphs/SingleParamUsageHeatMap'
import CreatePQModule from '@/components/crud_components/modules/CreatePQModule'
import SingleParamTDUsage from '@/components/panels/SingleParamTDUsage.vue'
import SingleValueDisplayCard from '@/components/display_components/SingleValueDisplayCard.vue'
import fileDownload from 'js-file-download'
import axios from 'axios'
import moment from 'moment'
import Plotly from "plotly.js"
export default {
    name:'PQModulePage',
    // props:['entureModule'],
    components:{
        InfoAlert,
        DeleteConfirmation,
        CreatePQModule,
        LatestUsagePanel,
        MultiParamLineGraph,
        SingleParamUsageHeatMap,
        SingleParamTDUsage,
        SingleValueDisplayCard
    },
    mounted(){
        this.module_id=this.$route.params.id
        this.getModule()
    },
    data(){
        return {
             showDialog:false,
            loading:false,
            info:'',
            search:null,
            showDismissibleAlert:false,
            showEdit:false,
            pqModule:null,
            module_id:null,
            pqModuleData:[],
            params:[],
            volt_unbalance_dur:0,
            layout:{
              title:'TDD',
              showlegend:true,
              colorway:this.$store.getters.getColorPalette().colorTypesCodeArray,
              font:{
                           color:this.$store.getters.getColorPalette().accentCode
                        },
                     plot_bgcolor:this.$store.getters.getColorPalette().backgroundColorCode+'02',

                paper_bgcolor:this.$store.getters.getColorPalette().background2ColorCode,
                    
                    autosize: true,
                    xaxis: {
                        tickangle: -12,
                        zeroline: false,
                        showgrid: true,
                        showticklabels: true,
                    color:this.$store.getters.getColorPalette().accentCode
                      },       
                      yaxis: {
                        showticklabels: true,
                        color:this.$store.getters.getColorPalette().accentCode
                      },
                      uniformtext: {
                        "mode": "hide",
                        "minsize": 1
                      }
            },
            headers:[
          {
            text: 'Date',
            align: 'start',
            filterable: false,
            //sortable:false,
            value: 'date',
            sort: (a, b) => (Number(a)-Number(b)),
            class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName
          },
          { text: 'Rated Voltage', value: 'ss_volt', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
          //{ text: 'TDD limit', value: 'tdd_lim',class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
          //{ text: 'TDD I', value: 'tdd_i',class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
          { text: 'Rated Secondary current of transformer (A)', value: 'scndr_cur', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
          { text: 'Source Impedance (%)', value: 'src_impedance', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
          { text: 'Average Demand Current', value: 'il',class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
          { text: 'Current Unbalance Duration', value: 'curr_unbalance_dur',class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
          { text: 'Current Unbalance ', value: 'curr_unbalance',class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
          { text: 'Voltage Unbalance Duration', value: 'volt_unbalance_dur',class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
          { text: 'Voltage Unbalance ', value: 'volt_unbalance',class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
          { text: 'ISC/IL', value: 'isc_il',class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
          { text: 'Report', value: 'filename',class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
          
          
        ],
        }
    },
    computed:{
        energyParams(){
            let op=[]
            if(this.pqModule){
                op=[{'name':'Active energy','param_id':this.pqModule.active_energy},
                {name:'Apparent Energy', param_id:this.pqModule.apparent_energy}]
            }
            return op
        },
        multiParams(){
            let mp=[]
        if(this.pqModule) {
                mp=[
                    {name:'Active Power',   param_id:this.pqModule.active_pw},
                    {name:'Apparent Power', param_id:this.pqModule.apparent_pw},
                    {name:'Reactive Power', param_id:this.pqModule.reactive_pw}
                ]
                //console.log(mp,'pq')
            }

            return mp
             
        }
       
    },
    methods:{
        convertTimeStamp(t){
        return t.format('YYYY-MM-DD')
    },
        createGraph(){
            let paramData={}
            paramData['tdd_i']={
                        x:[],
                        y:[],
                        type: 'scatter',
                        mode: 'lines+markers',
                        name:'TDD'
                    }
            paramData['tdd_lim']={
                        x:[],
                        y:[],
                        type: 'scatter',
                        mode: 'lines+markers',
                        name:'TDD Limit'
                    }
            for(let h of this.pqModuleData){
                console.log(h)
                if('tdd_i' in h){
                    
                    paramData['tdd_i']['x'].push(h.date)
                    paramData['tdd_i']['y'].push(Number(h['tdd_i']))
                    
                }
                if('tdd_lim' in h){
                    paramData['tdd_lim']['x'].push(h.date)
                    paramData['tdd_lim']['y'].push(Number(h['tdd_lim']))
                }
            }
            Plotly.newPlot(this.$refs.graph,Object.values(paramData),this.layout,{displaylogo:false, responsive: true})
      
        },
        getPQModuleData(){
            if(this.pqModule){
                this.loading=true
                let from_time=Number(moment().add(-30,'days').format("X"))
                let to_time=Number(moment().format("X"))
            axios.post(this.$store.state.api+'getPqDataBetweenTimestamp',{
                module_id:this.module_id,
                from_time:from_time,
                to_time:to_time
                },{headers: {
                    Authorization: 'Bearer '+ this.$store.state.jwt
                    }})
                    .then(response=>{
                        if(response.data.status=='success'){
                            
                            
                            this.pqModuleData=response.data.data
                            //this.info=response.data
                            //this.showDismissibleAlert=true
                            //this.$store.dispatch('refreshModules')
                            this.volt_unbalance_dur=0
                            for(let i of this.pqModuleData){
                                i['date']=moment(i['date']).format('YYYY-MM-DD')
                                if(i.volt_unbalance_dur && !isNaN(i.volt_unbalance_dur)){
                                    this.volt_unbalance_dur+=Number(i.volt_unbalance_dur)
                                }
                            }
                            //this.createGraph()
                        }else{
                            //this.loading=false
                            this.info=response.data.msg
                            this.showDismissibleAlert=true
                        }
                        this.loading=false
                    }).catch(err=>{
                        this.loading=false
                        this.info=err
                        this.showDismissibleAlert=true
                        
                    })

            }
        },
        getModule(){
        if(this.module_id){
            //console.log(this.entureModule,'em')
            this.loading=true
            axios.post(this.$store.state.api+'getPQModuleByID',{module_id:this.module_id},{headers: {
                    Authorization: 'Bearer '+ this.$store.state.jwt
                    }})
                    .then(response=>{
                        if(response.data.status=='success'){
                            
                            this.edit=false
                            this.pqModule=response.data.module
                            //this.info=response.data
                            //this.showDismissibleAlert=true
                            //this.$store.dispatch('refreshModules')
                            this.getPQModuleData()
                        }else{
                            //this.loading=false
                            this.info=response.data.msg
                            this.showDismissibleAlert=true
                        }
                        this.loading=false
                    }).catch(err=>{
                        this.loading=false
                        this.info=err
                        this.showDismissibleAlert=true
                        
                    })

            }
        },
        deleteModule(){
            if(this.module_id){
                this.loading=true
                let payload={
                    module_id:this.module_id
                }
                axios.post(this.$store.state.api+'deleteEntureModule',payload,{headers: {
                        Authorization: 'Bearer '+ this.$store.state.jwt
                       }})
                        .then(response=>{
                            if(response.data.status=='success'){
                                this.loading=false
                                this.edit=false
                                this.$store.dispatch('refreshModules')
                                
                            }else{
                                this.loading=false
                                this.info=response.data.msg
                                this.showDismissibleAlert=true
                            }
                        }).catch(err=>{
                            this.loading=false
                            this.info=err
                            this.showDismissibleAlert=true
                            
                        })
                    }
        },
        downloadReport(item){
            if(item){
                //this.subTableData=item
                this.loading=true
                console.log(item)
                axios.post(this.$store.state.api+'downloadPQReportByName',{
             filename:item.filename,
             module_id:this.pqModule.module_id
           },{headers: {
      Authorization: 'Bearer '+ this.$store.state.jwt
    },
    responseType: 'blob'}).then((response) => {
      this.loading=false
    /*const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', item+'.pdf'); //or any other extension
    document.body.appendChild(link);
    link.click();*/
    if(response.data.status && response.data.status=='error'){
      this.showDismissibleAlert=true
      this.info=response.data.msg
    }else{
      fileDownload(response.data,item.filename)
    }
    
}).catch(err=>{
  this.loading=false
  this.showDismissibleAlert=true
  this.info=err
});
            }else{
              
                this.showDismissibleAlert=!this.showDismissibleAlert
                this.info='Report file name not specified'
            }

        },
          cancel(){
          this.showDialog=false
        },
        confirm(){
          this.showDialog=false
        }
    },
    watch:{
        // entureModule:function (){
        //     this.getModule()
        // }
        '$route'() {
                  this.module_id = this.$route.params.id
                  this.getModule()

    },
    }
}
</script>
<style scoped>
.limit_height {
    max-height:400px;
}
.usage {
    color: #FDD835;
    font-size: 300%;
    font-weight: bold;
}
.unit{
    font-size: 0.6em;
}
</style>