<template>
    <v-container fluid  :style="{background: $store.getters.getColorPalette().background2ColorCode}">
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-toolbar :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().foregroundColorCode">
            <v-btn icon :dark="$store.getters.getColorPalette().isDark" @click="close"><v-icon>mdi-close</v-icon></v-btn>
            <v-toolbar-title>{{$store.getters.getTextMap().esg_scope_details}}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn :dark="$store.getters.getColorPalette().isDark" text @click="close">close</v-btn>
            </v-toolbar-items>
        </v-toolbar> 
        <v-card class="my-5 px-2" :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().backgroundColorCode">
            <v-card-title>{{$store.getters.getTextMap().select_custom_date_range}}</v-card-title>
            <v-row>
                <v-col>
                    <v-menu
                        v-model="menu1"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                        :dark="$store.getters.getColorPalette().isDark"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="fromTime"
                                :label="$store.getters.getTextMap().from_date"
                                prepend-icon="mdi-calendar"
                                readonly
                                :loading="loading"
                                v-bind="attrs"
                                v-on="on"
                                :rules="ISODateRules"
                                :dark="$store.getters.getColorPalette().isDark"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="fromTime"
                            @input="menu1 = false"
                            :dark="$store.getters.getColorPalette().isDark"
                        ></v-date-picker>
                    </v-menu>
                </v-col>
                <v-col>
                    <v-menu
                        v-model="menu2"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                        :dark="$store.getters.getColorPalette().isDark"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="toTime"
                                :label="$store.getters.getTextMap().to_date"
                                prepend-icon="mdi-calendar"
                                readonly
                                :loading="loading"
                                v-bind="attrs"
                                v-on="on"
                                :rules="ISODateRules"
                                :dark="$store.getters.getColorPalette().isDark"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="toTime"
                            @input="menu2 = false"
                            :dark="$store.getters.getColorPalette().isDark"
                        ></v-date-picker>
                    </v-menu>
                </v-col>
                <v-col style="margin-top:2%">
                    <v-btn  class="mx-2" small outlined v-if="!loading"  :color="$store.getters.getColorPalette().submitbtnColor" @click="getData(true)">
                        {{$store.getters.getTextMap().view}}
                    </v-btn>
                </v-col>
            </v-row>
        </v-card>
        <v-card flat :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().background2ColorCode">
            <v-row>
                <v-col>
                    <div>
                    <v-row v-if="this.machineDetailListTemp && this.machineDetailListTemp.length>0 && showGraphType" no-gutters >
                        <v-col align="left">
                            <v-btn-toggle :dark="$store.getters.getColorPalette().isDark"  v-model="days_toggle" mandatory>
                                <v-btn small>{{$store.getters.getTextMap().days_365}}</v-btn>
                                <v-btn small>{{$store.getters.getTextMap().days_180}}</v-btn>
                                <v-btn small>{{$store.getters.getTextMap().days_30}}</v-btn>
                            </v-btn-toggle>
                        </v-col>
                        <v-col  align="right">
                            <v-btn-toggle :dark="$store.getters.getColorPalette().isDark" v-model="graphType" mandatory>
                                <v-btn small>{{$store.getters.getTextMap().bar}}</v-btn>
                                <v-btn small>{{$store.getters.getTextMap().line}}</v-btn>
                            </v-btn-toggle>
                        </v-col>
                    </v-row>
                    </div>
                    <div ref="vis"></div>
                </v-col>
            </v-row> 
        </v-card>
        <v-row>
            <v-col>
                <v-card flat :dark="$store.getters.getColorPalette().isDark" >
                    <v-data-table
                        :headers="headers"
                        :items="tableData"
                        item-key="date"
                        :loading="loading"
                        :dark="$store.getters.getColorPalette().isDark"
                        loading-text="Loading... Please wait"
                    >
                        <template v-slot:top>
                            <v-toolbar flat>
                                <v-divider class="mx-4" inset vertical></v-divider>
                                <v-spacer></v-spacer>
                                <v-icon @click="getData">mdi-refresh</v-icon>
                            </v-toolbar>
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import Plotly from 'plotly.js'
import axios from 'axios'
import moment from 'moment'
import InfoAlert from '@/components/InfoAlert'
export default {
    name:'ESGScopeDetails',
    props:['machineDetailList','fromScopeCard'],
    components:{
        InfoAlert
    },
    data(){
        return {
            showMenu:false,
            info:'',
            showDismissibleAlert:false,
            loading:false,
            days_toggle:2,
            graphType:'bar',
            machineDetailListTemp:[],
            showGraphType:{
                type:Boolean,
                default:true
            },
            graphdata:{},
            dbData:[],
            lpgDummyData:[],
            tableData:[],
            menu:false,
            menu1:false,
            menu2:false,
            menu3:false,
            toTime:null,
            fromTime:null,
            headers:[
                {text: 'Date', value: 'date', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
            ],
            layout : {
                  barmode: 'group',
                  font:{
                        color:this.$store.getters.getColorPalette().accentCode,
                      },
                  plot_bgcolor:this.$store.getters.getColorPalette().background2ColorCode,
                  paper_bgcolor:this.$store.getters.getColorPalette().background2ColorCode,
                  showlegend:true,
                  autosize: true,
                  xaxis: {
                      tickangle: -12,
                      zeroline: false,
                      showgrid: true,
                      showticklabels: true,
                      color:this.$store.getters.getColorPalette().accentCode,
                    },       
                    yaxis: {
                      showticklabels: true,
                      color:this.$store.getters.getColorPalette().accentCode,
                    },
                    uniformtext: {
                      "mode": "hide",
                      "minsize": 1
                    }
                  },
                ISODateRules:[
                    v => !!v || 'Required',
                    v => /[0-9][0-9][0-9][0-9]-[0-9][0-9]-[0-9][0-9]$/.test(v) || 'It should be a Date',
                ],
        }
    },
    mounted(){
            this.machineDetailListTemp = this.machineDetailList
            if(this.$store.state.user.organisation_id==='26c7ea9e-8d15-4022-bd9e-e4166a6078e5' && this.machineDetailListTemp[0].scope===1 && this.fromScopeCard){
                this.machineDetailListTemp.push({'data':0,'machine_id':'xyz','machine_name':'LPG','machine_type':'LPG','module_id':this.machineDetailList[0].module_id,'scope':1})
            }
            this.getData()
    },
    computed:{
        fromTimeStamp(){
            if(this.fromTime){
                let dt=this.fromTime.toString()
                return moment(dt,'YYYY-MM-DD').format('YYYYMMDD');
            }else{
            return null
            }
        },
        toTimeStamp(){
            if(this.toTime){
                let dt=this.toTime.toString()
                return moment(dt,'YYYY-MM-DD').format('YYYYMMDD');
            }else{
                return null
            }
        },
    },
    methods:{
        init(){
            if(this.machineDetailListTemp && this.machineDetailListTemp.length>0){
                this.graphData={}
                let type=this.graphType==1?'line':'bar'
                for(let i of this.machineDetailListTemp ){
                    this.graphData[i.machine_name]={
                    x: [],
                    y: [],
                    name:i.machine_name,
                    type: type,
                    opacity:0.9,
                    marker: {
                        line: {
                        color: this.$store.getters.getColorPalette().accentCode,
                        width: 1
                        },
                        }
                    }
                }
            }
        },
        getTableData(){
            this.tableData=[]
            this.headers = []
            this.headers.push({text: 'Date', value: 'date', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName })
            let temp=this.dbData.reduce((prev,current)=>{
                if(!(current.date in prev)){
                    prev[current.date]={
                        date:moment(current.date,'YYYY-MM-DD').format('YYYY-MM-DD')
                        
                    }
                }
                prev[current.date][current.machine_id]=current['data']
                return prev
            },{})
            this.tableData=Object.values(temp)
            if(this.machineDetailListTemp && this.machineDetailListTemp.length>0){
                for(let h of this.machineDetailListTemp){
                    this.headers.push({text:h['machine_name'], value:h['machine_id'],class: "text-subtitle-1 text-capitalize "+this.$store.getters.getColorPalette().tableHeaderColorName})
                }
            }
        },
        createChart(){
            this.init()
            this.layout['title']= 'Energy Source Comparison'
            for(let i of this.dbData){
            if(!(i.label in this.graphData)){
              let type=this.graphType==1?'line':'bar'
              this.graphData[i.label]={
              x: [],
              y: [],
              name:i.label,
              type: type,
              opacity:0.9,
              marker: {
                line: {
                  color: this.$store.getters.getColorPalette().accentCode,
                  width: 1
                  },
                }
            }
            }
            this.graphData[i.label]['x'].push(moment(i.date,'YYYYMMDD').format('YYYY-MM-DD'))
            this.graphData[i.label]['y'].push(i.data)
          }
          Plotly.newPlot(this.$refs.vis, Object.values(this.graphData), this.layout, {displaylogo: false});
        },
        async getData(custome_date){
            let toDate = null
            let fromDate = null
            if(!custome_date){
                switch(this.days_toggle){
                case 0:
                    this.days=365
                    break;
                case 1:
                    this.days=180
                    break;
                case 2:
                    this.days=30
                    break;
            }
            fromDate = moment().subtract(this.days,'days').format('YYYYMMDD')
            }else{
                fromDate=this.fromTimeStamp,
                toDate=this.toTimeStamp
            }
        let payload = {
                module_id:this.machineDetailListTemp[0].module_id,
                machine_ids:[],
                from_date:fromDate,
                to_date:toDate
        }
        // console.log(payload)
        let labelMap={}
        for(let i of this.machineDetailListTemp){
            payload.machine_ids.push(i.machine_id)
            labelMap[i.machine_id]=i.machine_name
        }
            try{
            let response = await  axios.post(this.$store.state.api+'getESGModuleDataByDate',payload,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
            if(response.data.status==='success'){
                this.dbData = response.data.data
                if(this.fromScopeCard && this.$store.state.user.organisation_id==='26c7ea9e-8d15-4022-bd9e-e4166a6078e5'){

                    this.dbData = this.dbData.concat(this.LPGDummyData())
                }

                this.dbData.map(x=>{
                    x.label=labelMap[x.machine_id]?labelMap[x.machine_id]:'Label Not defined'
                })
                this.createChart()
                this.getTableData()
            }
        }catch(error){
            console.error(error)
            this.info = error;
            this.showDismissibleAlert = true;
            this.loading=false
        }
        },
        close(){
            this.$emit('close')
        },
        LPGDummyData(custom=false){
           this.lpgDummyData=[
          
                { "data": 2.82, "date": "2023-07-03", "label": "LPG", "machine_id": "xyz" },
                { "data": 4.41, "date": "2023-07-02", "label": "LPG", "machine_id": "xyz" },
                { "data": 3.67, "date": "2023-07-01", "label": "LPG", "machine_id": "xyz" },
                { "data": 1.73, "date": "2023-06-30", "label": "LPG", "machine_id": "xyz" },
                { "data": 4.05, "date": "2023-06-29", "label": "LPG", "machine_id": "xyz" },
                { "data": 2.89, "date": "2023-06-28", "label": "LPG", "machine_id": "xyz" },
                { "data": 3.13, "date": "2023-06-27", "label": "LPG", "machine_id": "xyz" },
                { "data": 1.52, "date": "2023-06-26", "label": "LPG", "machine_id": "xyz" },
                { "data": 2.78, "date": "2023-06-25", "label": "LPG", "machine_id": "xyz" },
                { "data": 4.87, "date": "2023-06-24", "label": "LPG", "machine_id": "xyz" },
                { "data": 2.41, "date": "2023-06-23", "label": "LPG", "machine_id": "xyz" },
                { "data": 4.09, "date": "2023-06-22", "label": "LPG", "machine_id": "xyz" },
                { "data": 3.25, "date": "2023-06-21", "label": "LPG", "machine_id": "xyz" },
                { "data": 3.87, "date": "2023-06-20", "label": "LPG", "machine_id": "xyz" },
                { "data": 1.95, "date": "2023-06-19", "label": "LPG", "machine_id": "xyz" },
                { "data": 2.42, "date": "2023-06-18", "label": "LPG", "machine_id": "xyz" },
                { "data": 4.63, "date": "2023-06-17", "label": "LPG", "machine_id": "xyz" },
                { "data": 3.09, "date": "2023-06-16", "label": "LPG", "machine_id": "xyz" },
                { "data": 2.14, "date": "2023-06-15", "label": "LPG", "machine_id": "xyz" },
                { "data": 3.98, "date": "2023-06-14", "label": "LPG", "machine_id": "xyz" },
                { "data": 1.65, "date": "2023-06-13", "label": "LPG", "machine_id": "xyz" },
                { "data": 2.37, "date": "2023-06-12", "label": "LPG", "machine_id": "xyz" },
                { "data": 3.71, "date": "2023-06-11", "label": "LPG", "machine_id": "xyz" },
                { "data": 1.98, "date": "2023-06-10", "label": "LPG", "machine_id": "xyz" },
                { "data": 2.84, "date": "2023-06-09", "label": "LPG", "machine_id": "xyz" },
                { "data": 4.32, "date": "2023-06-08", "label": "LPG", "machine_id": "xyz" },
                { "data": 3.05, "date": "2023-06-07", "label": "LPG", "machine_id": "xyz" },
                { "data": 1.92, "date": "2023-06-06", "label": "LPG", "machine_id": "xyz" },
                { "data": 4.09, "date": "2023-06-05", "label": "LPG", "machine_id": "xyz" },
                { "data": 3.41, "date": "2023-06-04", "label": "LPG", "machine_id": "xyz" },
                { "data": 1.76, "date": "2023-06-03", "label": "LPG", "machine_id": "xyz" },
                { "data": 2.95, "date": "2023-06-02", "label": "LPG", "machine_id": "xyz" },
                { "data": 3.97, "date": "2023-06-01", "label": "LPG", "machine_id": "xyz" },
                { "data": 4.23, "date": "2023-05-31", "label": "LPG", "machine_id": "xyz" },
                { "data": 2.15, "date": "2023-05-30", "label": "LPG", "machine_id": "xyz" },
                { "data": 1.99, "date": "2023-05-29", "label": "LPG", "machine_id": "xyz" },
                { "data": 4.42, "date": "2023-05-28", "label": "LPG", "machine_id": "xyz" },
                { "data": 3.03, "date": "2023-05-27", "label": "LPG", "machine_id": "xyz" },
                { "data": 2.87, "date": "2023-05-26", "label": "LPG", "machine_id": "xyz" },
                { "data": 1.81, "date": "2023-05-25", "label": "LPG", "machine_id": "xyz" },
                { "data": 4.13, "date": "2023-05-24", "label": "LPG", "machine_id": "xyz" },
                { "data": 3.75, "date": "2023-05-23", "label": "LPG", "machine_id": "xyz" },
                { "data": 4.99, "date": "2023-05-22", "label": "LPG", "machine_id": "xyz" },
                { "data": 2.22, "date": "2023-05-21", "label": "LPG", "machine_id": "xyz" },
                { "data": 1.76, "date": "2023-05-20", "label": "LPG", "machine_id": "xyz" },
                { "data": 4.68, "date": "2023-05-19", "label": "LPG", "machine_id": "xyz" },
                { "data": 3.12, "date": "2023-05-18", "label": "LPG", "machine_id": "xyz" },
                { "data": 3.85, "date": "2023-05-17", "label": "LPG", "machine_id": "xyz" },
                { "data": 2.45, "date": "2023-05-16", "label": "LPG", "machine_id": "xyz" },
                { "data": 1.97, "date": "2023-05-15", "label": "LPG", "machine_id": "xyz" },
                { "data": 4.04, "date": "2023-05-14", "label": "LPG", "machine_id": "xyz" },
                { "data": 3.26, "date": "2023-05-13", "label": "LPG", "machine_id": "xyz" },
                { "data": 2.11, "date": "2023-05-12", "label": "LPG", "machine_id": "xyz" },
                { "data": 4.85, "date": "2023-05-11", "label": "LPG", "machine_id": "xyz" },
                { "data": 3.04, "date": "2023-05-10", "label": "LPG", "machine_id": "xyz" },
                { "data": 2.33, "date": "2023-05-09", "label": "LPG", "machine_id": "xyz" },
                { "data": 4.32, "date": "2023-05-08", "label": "LPG", "machine_id": "xyz" },
                { "data": 2.91, "date": "2023-05-07", "label": "LPG", "machine_id": "xyz" },
                { "data": 4.56, "date": "2023-05-06", "label": "LPG", "machine_id": "xyz" },
                { "data": 1.98, "date": "2023-05-05", "label": "LPG", "machine_id": "xyz" },
                { "data": 2.76, "date": "2023-05-04", "label": "LPG", "machine_id": "xyz" },
                { "data": 3.78, "date": "2023-05-03", "label": "LPG", "machine_id": "xyz" },
                { "data": 1.91, "date": "2023-05-02", "label": "LPG", "machine_id": "xyz" },
                { "data": 4.15, "date": "2023-05-01", "label": "LPG", "machine_id": "xyz" },
                { "data": 3.63, "date": "2023-04-30", "label": "LPG", "machine_id": "xyz" },
                { "data": 1.92, "date": "2023-04-29", "label": "LPG", "machine_id": "xyz" },
                { "data": 4.26, "date": "2023-04-28", "label": "LPG", "machine_id": "xyz" },
                { "data": 3.55, "date": "2023-04-27", "label": "LPG", "machine_id": "xyz" },
                { "data": 2.04, "date": "2023-04-26", "label": "LPG", "machine_id": "xyz" },
                { "data": 4.61, "date": "2023-04-25", "label": "LPG", "machine_id": "xyz" },
                { "data": 2.73, "date": "2023-04-24", "label": "LPG", "machine_id": "xyz" },
                { "data": 4.36, "date": "2023-04-23", "label": "LPG", "machine_id": "xyz" },
                { "data": 3.14, "date": "2023-04-22", "label": "LPG", "machine_id": "xyz" },
                { "data": 2.01, "date": "2023-04-21", "label": "LPG", "machine_id": "xyz" },
                { "data": 4.97, "date": "2023-04-20", "label": "LPG", "machine_id": "xyz" },
                { "data": 2.46, "date": "2023-04-19", "label": "LPG", "machine_id": "xyz" },
                { "data": 3.69, "date": "2023-04-18", "label": "LPG", "machine_id": "xyz" },
                { "data": 1.93, "date": "2023-04-17", "label": "LPG", "machine_id": "xyz" },
                { "data": 4.02, "date": "2023-04-16", "label": "LPG", "machine_id": "xyz" },
                { "data": 2.95, "date": "2023-04-15", "label": "LPG", "machine_id": "xyz" },
                { "data": 1.87, "date": "2023-04-14", "label": "LPG", "machine_id": "xyz" },
                { "data": 3.61, "date": "2023-04-13", "label": "LPG", "machine_id": "xyz" },
                { "data": 4.41, "date": "2023-04-12", "label": "LPG", "machine_id": "xyz" },
                { "data": 1.95, "date": "2023-04-11", "label": "LPG", "machine_id": "xyz" },
                { "data": 4.32, "date": "2023-04-10", "label": "LPG", "machine_id": "xyz" },
                { "data": 2.97, "date": "2023-04-09", "label": "LPG", "machine_id": "xyz" },
                { "data": 3.08, "date": "2023-04-08", "label": "LPG", "machine_id": "xyz" },
                { "data": 2.27, "date": "2023-04-07", "label": "LPG", "machine_id": "xyz" },
                { "data": 1.87, "date": "2023-04-06", "label": "LPG", "machine_id": "xyz" },
                { "data": 4.03, "date": "2023-04-05", "label": "LPG", "machine_id": "xyz" },
                { "data": 3.13, "date": "2023-04-04", "label": "LPG", "machine_id": "xyz" },
                { "data": 3.61, "date": "2023-01-04", "label": "LPG", "machine_id": "xyz" },
                { "data": 2.98, "date": "2023-01-05", "label": "LPG", "machine_id": "xyz" },
                { "data": 4.23, "date": "2023-01-06", "label": "LPG", "machine_id": "xyz" },
                { "data": 2.75, "date": "2023-01-07", "label": "LPG", "machine_id": "xyz" },
                { "data": 1.92, "date": "2023-01-08", "label": "LPG", "machine_id": "xyz" },
                { "data": 3.41, "date": "2023-01-09", "label": "LPG", "machine_id": "xyz" },
                { "data": 4.01, "date": "2023-01-10", "label": "LPG", "machine_id": "xyz" },
                { "data": 3.15, "date": "2023-01-11", "label": "LPG", "machine_id": "xyz" },
                { "data": 2.64, "date": "2023-01-12", "label": "LPG", "machine_id": "xyz" },
                { "data": 3.78, "date": "2023-01-13", "label": "LPG", "machine_id": "xyz" },
                { "data": 2.87, "date": "2023-01-14", "label": "LPG", "machine_id": "xyz" },
                { "data": 1.95, "date": "2023-01-15", "label": "LPG", "machine_id": "xyz" },
                { "data": 3.62, "date": "2023-01-16", "label": "LPG", "machine_id": "xyz" },
                { "data": 4.02, "date": "2023-01-17", "label": "LPG", "machine_id": "xyz" },
                { "data": 3.35, "date": "2023-01-18", "label": "LPG", "machine_id": "xyz" },
                { "data": 2.53, "date": "2023-01-19", "label": "LPG", "machine_id": "xyz" },
                { "data": 3.81, "date": "2023-01-20", "label": "LPG", "machine_id": "xyz" },
                { "data": 2.96, "date": "2023-01-21", "label": "LPG", "machine_id": "xyz" },
                { "data": 1.82, "date": "2023-01-22", "label": "LPG", "machine_id": "xyz" },
                { "data": 3.76, "date": "2023-01-23", "label": "LPG", "machine_id": "xyz" },
                { "data": 2.68, "date": "2023-01-24", "label": "LPG", "machine_id": "xyz" },
                { "data": 3.95, "date": "2023-01-25", "label": "LPG", "machine_id": "xyz" },
                { "data": 2.56, "date": "2023-01-26", "label": "LPG", "machine_id": "xyz" },
                { "data": 3.85, "date": "2023-01-27", "label": "LPG", "machine_id": "xyz" },
                { "data": 2.77, "date": "2023-01-28", "label": "LPG", "machine_id": "xyz" },
                { "data": 1.91, "date": "2023-01-29", "label": "LPG", "machine_id": "xyz" },
                { "data": 3.61, "date": "2023-01-30", "label": "LPG", "machine_id": "xyz" },
                { "data": 2.89, "date": "2023-01-31", "label": "LPG", "machine_id": "xyz" },
                { "data": 3.85, "date": "2023-02-01", "label": "LPG", "machine_id": "xyz" },
                { "data": 2.93, "date": "2023-02-02", "label": "LPG", "machine_id": "xyz" },
                { "data": 1.99, "date": "2023-02-03", "label": "LPG", "machine_id": "xyz" },
                { "data": 3.71, "date": "2023-02-04", "label": "LPG", "machine_id": "xyz" },
                { "data": 2.88, "date": "2023-02-05", "label": "LPG", "machine_id": "xyz" },
                { "data": 4.12, "date": "2023-02-06", "label": "LPG", "machine_id": "xyz" },
                { "data": 3.16, "date": "2023-02-07", "label": "LPG", "machine_id": "xyz" },
                { "data": 2.45, "date": "2023-02-08", "label": "LPG", "machine_id": "xyz" },
                { "data": 3.99, "date": "2023-02-09", "label": "LPG", "machine_id": "xyz" },
                { "data": 2.81, "date": "2023-02-10", "label": "LPG", "machine_id": "xyz" },
                { "data": 1.92, "date": "2023-02-11", "label": "LPG", "machine_id": "xyz" },
                { "data": 3.72, "date": "2023-02-12", "label": "LPG", "machine_id": "xyz" },
                { "data": 2.83, "date": "2023-02-13", "label": "LPG", "machine_id": "xyz" },
                { "data": 4.11, "date": "2023-02-14", "label": "LPG", "machine_id": "xyz" },
                { "data": 3.16, "date": "2023-02-15", "label": "LPG", "machine_id": "xyz" },
                { "data": 2.41, "date": "2023-02-16", "label": "LPG", "machine_id": "xyz" },
                { "data": 4.05, "date": "2023-02-17", "label": "LPG", "machine_id": "xyz" },
                { "data": 2.92, "date": "2023-02-18", "label": "LPG", "machine_id": "xyz" },
                { "data": 1.92, "date": "2023-02-19", "label": "LPG", "machine_id": "xyz" },
                { "data": 3.59, "date": "2023-02-20", "label": "LPG", "machine_id": "xyz" },
                { "data": 2.87, "date": "2023-02-21", "label": "LPG", "machine_id": "xyz" },
                { "data": 4.12, "date": "2023-02-22", "label": "LPG", "machine_id": "xyz" },
                { "data": 3.16, "date": "2023-02-23", "label": "LPG", "machine_id": "xyz" },
                { "data": 2.49, "date": "2023-02-24", "label": "LPG", "machine_id": "xyz" },
                { "data": 4.07, "date": "2023-02-25", "label": "LPG", "machine_id": "xyz" },
                { "data": 2.88, "date": "2023-02-26", "label": "LPG", "machine_id": "xyz" },
                { "data": 1.98, "date": "2023-02-27", "label": "LPG", "machine_id": "xyz" },
                { "data": 3.69, "date": "2023-02-28", "label": "LPG", "machine_id": "xyz" },
                { "data": 2.82, "date": "2023-03-01", "label": "LPG", "machine_id": "xyz" },
                { "data": 4.11, "date": "2023-03-02", "label": "LPG", "machine_id": "xyz" },
                { "data": 3.09, "date": "2023-03-03", "label": "LPG", "machine_id": "xyz" },
                { "data": 2.31, "date": "2023-03-04", "label": "LPG", "machine_id": "xyz" },
                { "data": 3.91, "date": "2023-03-05", "label": "LPG", "machine_id": "xyz" },
                { "data": 2.67, "date": "2023-03-06", "label": "LPG", "machine_id": "xyz" },
                { "data": 1.92, "date": "2023-03-07", "label": "LPG", "machine_id": "xyz" },
                { "data": 3.78, "date": "2023-03-08", "label": "LPG", "machine_id": "xyz" },
                { "data": 2.81, "date": "2023-03-09", "label": "LPG", "machine_id": "xyz" },
                { "data": 4.02, "date": "2023-03-10", "label": "LPG", "machine_id": "xyz" },
                { "data": 3.15, "date": "2023-03-11", "label": "LPG", "machine_id": "xyz" },
                { "data": 2.38, "date": "2023-03-12", "label": "LPG", "machine_id": "xyz" },
                { "data": 4.02, "date": "2023-03-13", "label": "LPG", "machine_id": "xyz" },
                { "data": 2.74, "date": "2023-03-14", "label": "LPG", "machine_id": "xyz" },
                { "data": 1.98, "date": "2023-03-15", "label": "LPG", "machine_id": "xyz" },
                { "data": 3.78, "date": "2023-03-16", "label": "LPG", "machine_id": "xyz" },
                { "data": 2.73, "date": "2023-03-17", "label": "LPG", "machine_id": "xyz" },
                { "data": 4.09, "date": "2023-03-18", "label": "LPG", "machine_id": "xyz" },
                { "data": 3.12, "date": "2023-03-19", "label": "LPG", "machine_id": "xyz" },
                { "data": 2.34, "date": "2023-03-20", "label": "LPG", "machine_id": "xyz" },
                { "data": 3.89, "date": "2023-03-21", "label": "LPG", "machine_id": "xyz" },
                { "data": 2.66, "date": "2023-03-22", "label": "LPG", "machine_id": "xyz" },
                { "data": 1.89, "date": "2023-03-23", "label": "LPG", "machine_id": "xyz" },
                { "data": 3.81, "date": "2023-03-24", "label": "LPG", "machine_id": "xyz" },
                { "data": 2.68, "date": "2023-03-25", "label": "LPG", "machine_id": "xyz" },
                { "data": 4.02, "date": "2023-03-26", "label": "LPG", "machine_id": "xyz" },
                { "data": 3.09, "date": "2023-03-27", "label": "LPG", "machine_id": "xyz" },
                { "data": 2.31, "date": "2023-03-28", "label": "LPG", "machine_id": "xyz" },
                { "data": 3.85, "date": "2023-03-29", "label": "LPG", "machine_id": "xyz" },
                { "data": 2.61, "date": "2023-03-30", "label": "LPG", "machine_id": "xyz" },
                { "data": 1.84, "date": "20230-3-31", "label": "LPG", "machine_id": "xyz" },
                { "data": 3.77, "date": "2023-04-01", "label": "LPG", "machine_id": "xyz" },
                { "data": 2.62, "date": "2023-04-02", "label": "LPG", "machine_id": "xyz" },
                { "data": 4.01, "date": "2023-04-03", "label": "LPG", "machine_id": "xyz" },
                { "data": 3.09, "date": "2023-04-04", "label": "LPG", "machine_id": "xyz" }
        ]
        
        switch(this.days_toggle){
                case 0:
                    this.lpgDummyData = this.lpgDummyData.slice(0,180);
                    break;
                case 1:
                    this.lpgDummyData = this.lpgDummyData.slice(0,180);
                    break;
                case 2:
                    this.lpgDummyData = this.lpgDummyData.slice(0,30);
                    break;
        }
        if(custom){
                const startDate = moment(this.fromTime,'YYYY-MM-DD').format('YYYYMMDD').toString();
                const endDate = moment(this.toTime,'YYYY-MM-DD').format('YYYYMMDD').toString();
                this.lpgDummyData = this.lpgDummyData.filter((obj) => {
                    const date = obj.date;
                    return date >= startDate && date <= endDate;
                });
            }
        return this.lpgDummyData
        }
    },
    watch:{
        days_toggle(){
            this.getData()
        },
        graphType: function (){
            this.createChart()
        }
    }
}
</script>