<template>
    <v-container fluid class="px-0 mx-0">
        <v-card :style="{'border':'1px solid'+$store.getters.getColorPalette().panelBorderColorCode}" flat :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().background2ColorCode" >
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
        <v-row><v-col>
            <v-row>
                <v-col  align="right" class="mx-1">
                    <v-btn-toggle :dark="$store.getters.getColorPalette().isDark" v-model="graphType" mandatory>
                        <v-btn small>{{$store.getters.getTextMap().bar}}r</v-btn>
                        <v-btn small>{{$store.getters.getTextMap().line}}</v-btn>
                    </v-btn-toggle>
                </v-col>
            </v-row>
            <div  v-if="this.module" ref="vis"></div>
        </v-col></v-row>
        </v-card>
    </v-container>
</template>

<script>
import Plotly from 'plotly.js'
import axios from 'axios'
import moment from 'moment'
import InfoAlert from '@/components/InfoAlert'

export default {
    name:'ESGModuleBarGraph',
    props:['module'],
    components:{
        InfoAlert,
    },
    data(){
        return{
            showDialog:false,
            x:0,
            y:0,
            graphType:'bar',
            scope1_x_axis:[],
            scope1_y_axis:[],
            scope2_x_axis:[],
            scope2_y_axis:[],
            showMenu:false,
            temp_data:[],
            info:'',
            loading:false,
            showDismissibleAlert:false,
            colorCode:this.$store.getters.getColorPalette().gradientColorCodes,
            graphData:{},
            layout : {
                    colorway:this.$store.getters.getColorPalette().gradientColorCodes,
                    font:{
                          color:this.$store.getters.getColorPalette().accentCode,
                        },
                    plot_bgcolor:this.$store.getters.getColorPalette().background2ColorCode,
                    paper_bgcolor:this.$store.getters.getColorPalette().background2ColorCode,
                    showlegend:true,
                    autosize: true,
                    xaxis: {
                        tickangle: -12,
                        zeroline: false,
                        showgrid: true,
                        showticklabels: true,
                        color:this.$store.getters.getColorPalette().accentCode,
                      },       
                      yaxis: {
                        showticklabels: true,
                        color:this.$store.getters.getColorPalette().accentCode,
                        title: {
                        text: 'tCO₂',
                        font: {
                            size: 18,
                            color: this.$store.getters.getColorPalette().accentCode,
                        }
                         },
                      },
                      uniformtext: {
                        "mode": "hide",
                        "minsize": 1
                      }
                    }
        }
    },
    mounted(){
        if(this.module){
            this.getData()
        }
    },
    methods:{
        show (e) {
        e.preventDefault()
        this.showMenu = false
        this.x = e.clientX
        this.y = e.clientY
        this.$nextTick(() => {
          this.showMenu = true
        })
      },
      createScopeData(count,db_data,scope,machine_type){
        if(!this.graphData[scope]){
            this.graphData[scope]=[]
        }
        this.graphData[scope].push({'machine':machine_type,'db_data':db_data})
        // console.log(this.graphData)
        if(this.module && count===this.module.length){
            let mergedResultScope1 = {};
            for(let i of this.graphData['scope1']){
            for(let j of i['db_data']){
                let { date, data } = j;
                date = moment(date,'YYYYMMDD').format('YYYY-MM-DD')
                if(i['machine']==='solar'){
                    mergedResultScope1[date] = (mergedResultScope1[date] || 0) - data;
                }else{
                    mergedResultScope1[date] = (mergedResultScope1[date] || 0) + data;
                }

            }
            console.log(mergedResultScope1)
        }
        const mergedListScope1 = Object.entries(mergedResultScope1).map(([date, data]) => ({
            date,
            data,
        }));

        let mergedResultScope2 = {};
        for(let i of this.graphData['scope2']){
            for(let j of i['db_data']){
                let { date, data } = j;
                date = moment(date,'YYYYMMDD').format('YYYY-MM-DD')
                mergedResultScope2[date] = (mergedResultScope2[date] || 0) + data;
            }
        }
        
        const mergedListScope2 = Object.entries(mergedResultScope2).map(([date, data]) => ({
            date,
            data,
        }));
        for(let i of mergedListScope1){
            this.scope1_x_axis.push(i.date)
            this.scope1_y_axis.push(i.data)
        }

        for(let i of mergedListScope2){
            this.scope2_x_axis.push(i.date)
            this.scope2_y_axis.push(i.data)
        }
        }
      },
      createChart(){
        this.layout['title']= '30 Days'
        let type=this.graphType==1?'line':'bar'
        // this.layout['annotations']=[{
        //     font: {
        //         size: 22
        //     },
        //     showarrow: false,
        //     x: 0.5,
        //     y: 0.5
        // }]
        var trace1 = {
            x:this.scope1_x_axis,
            y:this.scope1_y_axis,
            name:'Scope 1',
            type:type,
            opacity:0.9,
            marker: {
                line: {
                  color: this.$store.getters.getColorPalette().accentCode,
                  width: 1
                  },
                }
        }
        var trace2 ={
            x:this.scope2_x_axis,
            y:this.scope2_y_axis,
            name:'Scope 2',
            type:type,
            opacity:0.9,
            marker: {
                line: {
                  color: this.$store.getters.getColorPalette().accentCode,
                  width: 1
                  },
                }
        }
        var data = [trace1, trace2];
        // console.log(data)
        Plotly.newPlot(this.$refs.vis, data, this.layout, {displaylogo: false});
      },
      getData(){
        this.graphData={}
        this.graphData['scope1'] = []
        this.graphData['scope2'] = []
        let from_date=moment().subtract(30,'days').format('YYYYMMDD')
        // from_date=from_date.startOf('year').format('YYYYMMDD')
        if(this.module){
            let count = 1
            for(let i of this.module){
                let payload={
                    module_id:i.module_id,
                    machine_ids:[i.machine_id],
                    from_date:from_date,
                    // timeframe:'month'
                }

                axios.post(this.$store.state.api+'getESGModuleDataByDate',payload,{headers: {
                    Authorization: 'Bearer '+ this.$store.state.jwt
                }}).then(response=>{
                    if(response.data.status==='success'){
                        // console.log(response.data)
                        if(i.scope===1){
                            this.createScopeData(count,response.data.data,'scope1',i.machine_type)
                        }else{
                            this.createScopeData(count,response.data.data,'scope2',i.machine_type)
                        }
                    }
                    count = count + 1
                    this.createChart()
                }).catch(err=>{
                    console.error(err)
                    this.loading=false
                })
            }

        }
      },
    },
    watch:{
        graphType: function (){
            this.createChart()
        }
    }
}
</script>

<style scoped>
.limit_height {
    max-height:400px;
}
</style>