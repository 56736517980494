import { render, staticRenderFns } from "./PetrolEmissionTable.vue?vue&type=template&id=628af6f2&scoped=true&"
import script from "./PetrolEmissionTable.vue?vue&type=script&lang=js&"
export * from "./PetrolEmissionTable.vue?vue&type=script&lang=js&"
import style0 from "./PetrolEmissionTable.vue?vue&type=style&index=0&id=628af6f2&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "628af6f2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VCard,VCardTitle,VContainer,VDataTable,VDivider,VSpacer,VTextField,VToolbar,VToolbarTitle})
