<template>
    <span class="text-center">
    
    <v-btn :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" small text outlined   @click="dialog=!dialog" >
      {{ $store.getters.getTextMap().create_module }}<v-icon right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().btnborderColorCode" small>mdi-view-module-outline</v-icon>
      </v-btn>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
    <v-card :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().backgroundColorCode" >
    <v-toolbar
        :dark="$store.getters.getColorPalette().isDark"
           :color="$store.getters.getColorPalette().foregroundColorCode"
        >
          <v-btn
            icon
         :dark="$store.getters.getColorPalette().isDark"
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title> {{ $store.getters.getTextMap().create_module }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              :dark="$store.getters.getColorPalette().isDark"
              text
              @click="dialog = false"
            >
            {{ $store.getters.getTextMap().close }}
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-tabs
    fixed-tabs 
:dark="$store.getters.getColorPalette().isDark"
    v-model="tab"
  >
    <v-tab>
      {{ $store.getters.getTextMap().busbar_temp_module }}
    </v-tab>
    <v-tab>
      {{ $store.getters.getTextMap().power_quality_module }}

    </v-tab>
    <v-tab>
      {{ $store.getters.getTextMap().esg_module }}

    </v-tab>
    
  </v-tabs>
  <v-tabs-items v-model="tab" :style="{background: $store.getters.getColorPalette().backgroundColorCode}">
    <v-tab-item >
      <CreateBusbarTempModule v-on:close="dialog=false"/>
    </v-tab-item>
    <v-tab-item>
      <CreatePQModule v-on:close="dialog=false"/>
    </v-tab-item>
    <v-tab-item>
      <CreateESGModule v-on:close="dialog=false"/>
    </v-tab-item>
  </v-tabs-items>
      
    </v-card>
    </v-dialog>
  </span>
</template>

<script>
import CreateBusbarTempModule from '@/components/crud_components/modules/CreateBusbarTempModule'
import CreatePQModule from '@/components/crud_components/modules/CreatePQModule'
import CreateESGModule from '@/components/crud_components/modules/CreateESGModule'
export default {
    name:'CreateModuleWindow',
    components:{
        CreateBusbarTempModule,
        CreatePQModule,
        CreateESGModule
    },
    data(){
        return {
            dialog:false,
            tab:null
        }
    },
}
</script>