<template>
    <v-container fluid :style="{'background-color': $store.getters.getColorPalette().backgroundColorCode, color: $store.getters.getColorPalette().accentCode}">
        
      <component v-bind:is="componentMap[emissionDetail.kpi]" :emissionDetail="emissionDetail"></component>

    </v-container>
    </template>
    
    <script>
    // import axios from 'axios'
    // import InfoAlert from '@/components/InfoAlert'
    // import EditModbusParameter from '@/components/crud_components/EditModbusParameter'
    import esgData from '@/data/esg_data'
import EBEmissionTable from '@/components/tables/esg/EBEmissionTable'
import DieselEmissionTable from '@/components/tables/esg/DieselEmissionTable'
import LPGEmissionTable from '@/components/tables/esg/LPGEmissionTable'
import PetrolEmissionTable from '@/components/tables/esg/PetrolEmissionTable'
    
    export default {
        name:'Co2DrillDownTable',
        props:['emissionDetail'],
        components:{
            // InfoAlert,

            // EditModbusParameter
        },
        mounted(){
            // this.getData()
            this.populateTable()
        },
        data(){
            return {
                emissionData:esgData,
                tableData:[],
                search:'',
                info:'',
                showDismissibleAlert:false,
                loading:false,
                drillDownVisible:false,
                drillDown:null,
                expanded: [],
                headers: [
              
              { text: 'Month', value: 'timestamp', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
              { text: 'Power Consumption - Chennai Plant', value: 'Power Consumption - Chennai Plant', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
             
              
              
            ],
            componentMap:{
                'eb':EBEmissionTable,
                'diesel':DieselEmissionTable,
                'lpg':LPGEmissionTable,
                'petrol':PetrolEmissionTable

            },
            headerList:{
                "Electricity":[
                { text: 'Month', value: 'timestamp', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
              { text: 'Power Consumption - Chennai Plant', value: 'consumption_chen', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
              { text: 'Power Consumption (Ahmedabad Plant)', value: 'consumption_amd', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
              { text: 'Power Consumption (India Others)', value: 'consumption_oth', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
              { text: 'Total Power Consumption (KWh)', value: 'consumption_total', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
              { text: 'EMISSION FACTOR OF CO2 Emissions (kgCO2/KWh)', value: 'conversion_factor', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
              { text: 'CO2 Emissions', value: 'emission', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName }
                ],
            "DG Operation":[
                { text: 'Month', value: 'timestamp', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
              { text: 'Power Consumption - Chennai Plant', value: 'consumption_chen', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
              { text: 'Power Consumption (Ahmedabad Plant)', value: 'consumption_amd', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
              { text: 'Power Consumption (India Others)', value: 'consumption_oth', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
              { text: 'Total Diesel Consumption (L)', value: 'consumption_total', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
              { text: 'Density', value: 'density', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
              { text: 'Mass (kg)', value: 'mass', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
              { text: 'Emission Factor (kg/kwh)', value: 'conversion_factor', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
              { text: 'CO2 Emissions', value: 'emission', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName }
                ]
            }
            }
        },
        computed:{


            
        },
        methods:{
            showTable(item){
                this.drillDownVisible=true
                this.drillDown=item
                

            },
            populateTable(){
                if(this.emissionDetail ){
                this.headers=this.headerList[this.emissionDetail.kpi_tag]
                this.tableData=esgData['details'][this.emissionDetail.kpi_tag]
                
                }
                

                
            }
        },
        watch:{
            emissionDetail:{
          handler(){
              this.populateTable()
          },
          deep:true
      },
    }
    }
    </script>
    <style scoped>
    .v-card__title{
      background-color:#bdbdbd00;
    }
    .theme--light.v-toolbar.v-sheet{
      background-color: #B2DFDB00;
    }
    .theme--light.v-data-table{
       background-color: #B2DFDB00;
    }
    </style>