<template>
    <v-container fluid :style="{'background-color': $store.getters.getColorPalette().backgroundColorCode, color: $store.getters.getColorPalette().accentCode}">
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
      <v-card :dark="$store.getters.getColorPalette().isDark">
        <v-card-title>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            :label="$store.getters.getTextMap().search"
            single-line
            hide-details
          :dark="$store.getters.getColorPalette().isDark"
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="tableData"
          item-key="timestamp"
          :search="search"
          :loading="loading"
          :dark="$store.getters.getColorPalette().isDark"
        loading-text="Loading... Please wait"
        >
        <template v-slot:top>
          <v-toolbar
            flat
            
          >
            <v-toolbar-title>{{$store.getters.getTextMap().eb_emissions}}
</v-toolbar-title>
            <v-divider
              class="mx-4"
              inset
              vertical
            ></v-divider>
            <v-spacer></v-spacer>
            
            
          </v-toolbar>
              </template>
              
              <template
        
        v-slot:item.consumption_total="{ item }"
      >
      {{ item.consumption_chen + item.consumption_amd + item.consumption_oth }}
      </template>
              <template
        
        v-slot:item.conversion_factor="{  }"
      >
      {{ emissionData['factors']['eb']['conversion_factors']["kgco2/kwh"]}}
      </template>
              <template
        
        v-slot:item.emission="{ item }"
      >
      {{Math.round((item.consumption_chen + item.consumption_amd + item.consumption_oth)* emissionData['factors']['eb']['conversion_factors']["kgco2/kwh"]*100)/100}}
      </template>
        </v-data-table>
      </v-card>
      
    </v-container>
    </template>
    
    <script>
    // import axios from 'axios'
    import InfoAlert from '@/components/InfoAlert'
    // import EditModbusParameter from '@/components/crud_components/EditModbusParameter'
    import esgData from '@/data/esg_data'
    
    export default {
        name:'EBEmissionTable',
        props:['emissionDetail'],
        components:{
            InfoAlert,
            // EditModbusParameter
        },
        mounted(){
            // this.getData()
            this.populateTable()
        },
        data(){
            return {
                emissionData:esgData,
                tableData:[],
                search:'',
                info:'',
                showDismissibleAlert:false,
                loading:false,
                drillDownVisible:false,
                drillDown:null,
                expanded: [],
                headers: [
              
                { text: 'Month', value: 'timestamp', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
              { text: 'Power Consumption - Chennai Plant', value: 'consumption_chen', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
              { text: 'Power Consumption (Ahmedabad Plant)', value: 'consumption_amd', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
              { text: 'Power Consumption (India Others)', value: 'consumption_oth', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
              { text: 'Total Power Consumption (KWh)', value: 'consumption_total', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
              { text: 'EMISSION FACTOR OF CO2 Emissions (kgCO2/KWh)', value: 'conversion_factor', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName },
              { text: 'CO2 Emissions (kG Co2)', value: 'emission', class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName }
                
              
            ],
            
            }
        },
        computed:{


            
        },
        methods:{
            showTable(item){
                this.drillDownVisible=true
                this.drillDown=item
                

            },
            populateTable(){
                if(this.emissionDetail ){
                this.tableData=esgData['details'][this.emissionDetail.kpi_tag]
                console.log('inside')
                }
                console.log('populate table')

                
            }
        },
        watch:{
            emissionDetail:{
          handler(){
              this.populateTable()
          },
          deep:true
      },
    }
    }
    </script>
    <style scoped>
    .v-card__title{
      background-color:#bdbdbd00;
    }
    .theme--light.v-toolbar.v-sheet{
      background-color: #B2DFDB00;
    }
    .theme--light.v-data-table{
       background-color: #B2DFDB00;
    }
    </style>