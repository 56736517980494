<template>
    <v-container fluid class="px-0 mx-0">
        <v-card :style="{'border-radius':'5px','border':'1px solid'+$store.getters.getColorPalette().panelBorderColorCode}" flat :dark="$store.getters.getColorPalette().isDark"  :color="$store.getters.getColorPalette().background2ColorCode">
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
        <v-row>
            <v-col>
            <div>
                <v-simple-table height="200px" :style="{background: 'transparent'}" >
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left"><strong></strong></th>
                                <th class="text-left"><strong>{{$store.getters.getTextMap().mtd}}</strong></th>
                                <th class="text-left"><strong>{{$store.getters.getTextMap().lmtd}}</strong></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr :key="item.scope" v-for="item in tableData">
                                <td><strong>{{ item.scope }}</strong></td>
                                <td><strong>{{ item.mtd.toFixed(2) }} tCO₂</strong></td>
                                <td><strong>{{ item.lmtd.toFixed(2) }} tCO₂</strong></td>
                                <td></td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </div>
        </v-col>
        </v-row>
        </v-card>
    </v-container>
</template>

<script>
import moment from 'moment'
import axios from 'axios'
import InfoAlert from '@/components/InfoAlert'

export default {
    name:'ESGModuleScopeWiseTable',
    props:['module'],
    components:{
        InfoAlert,
    },
    data(){
        return{
            info:'',
            loading:false,
            showDismissibleAlert:false,
            scopeDataTemp1:{
                'mtd':0,
                'lmtd':0
            },
            scopeDataTemp2:{
                'mtd':0,
                'lmtd':0
            },
            scopeData:[],
            // tableData:[],
            calculateMTDDate:false,
            calculateLMTDDate:false,
        }
    },
    mounted(){
        if(this.module){
            this.getData()
        }
    },
    computed:{
        tableData(){
            return [this.scopeDataTemp1,this.scopeDataTemp2]
        }
    },
    methods:{
        calculateScopeDataMTD(count,db_data,scope,machine_type){
            let data_temp = 0
                for (let i of db_data){
                    data_temp = i['data'] + data_temp
                }
                if(scope===1){
                    if(!this.scopeDataTemp1['scope']){
                        this.scopeDataTemp1['scope']='Scope 1'
                    }
                    if(machine_type==='solar'){
                        this.scopeDataTemp1['mtd']=this.scopeDataTemp1['mtd'] - data_temp
                    }else{
                        this.scopeDataTemp1['mtd']=this.scopeDataTemp1['mtd'] + data_temp
                    }
                }else{
                    if(!this.scopeDataTemp2['scope']){
                        this.scopeDataTemp2['scope']='Scope 2'
                    }
                    this.scopeDataTemp2['mtd']=this.scopeDataTemp2['mtd'] + data_temp
                }
                // if(this.module && count === this.module.length && this.tableData.length<2){
                //     this.tableData.push(this.scopeDataTemp1)
                //     this.tableData.push(this.scopeDataTemp2)
                // }
                // console.log(this.calculateMTDDate,this.calculateLMTDDate)

    },
        calculateScopeDataLMTD(count,db_data,scope,machine_type){
            let data_temp = 0
                for (let i of db_data){
                    data_temp = i['data'] + data_temp
                }
                if(scope===1){
                    if(!this.scopeDataTemp1['scope']){
                        this.scopeDataTemp1['scope']='Scope 1'
                    }
                    if(machine_type==='solar'){
                        this.scopeDataTemp1['lmtd']=this.scopeDataTemp1['lmtd'] - data_temp
                    }else{
                        this.scopeDataTemp1['lmtd']=this.scopeDataTemp1['lmtd'] + data_temp
                    }
                }else{
                    if(!this.scopeDataTemp2['scope']){
                        this.scopeDataTemp2['scope']='Scope 2'
                    }
                    this.scopeDataTemp2['lmtd']=this.scopeDataTemp2['lmtd'] + data_temp
                }
                if(this.module && count===this.module.length){
                    this.calculateLMTDDate=true
                }

                // if(this.module && count === this.module.length && this.tableData.length<2){
                //     this.tableData.push(this.scopeDataTemp1)
                //     this.tableData.push(this.scopeDataTemp2)
                // }
        },
        getData() {

            this.scopeDataTemp1={
                'mtd':0,
                'lmtd':0
            }
            this.scopeDataTemp2={
                'mtd':0,
                'lmtd':0
            }
            this.getMTDData()
            this.getLMTDData()
            
        },
        getMTDData(){
            let from_date_mtd=moment().subtract(1,'days')
            from_date_mtd=from_date_mtd.startOf('month').format('YYYYMMDD')
            let count = 1
            for(let i of this.module){
                let payload_mtd={
                    module_id:i.module_id,
                    machine_id:i.machine_id,
                    from_date:from_date_mtd,
                }
                axios.post(this.$store.state.api+'getESGModuleGroupedDataByTimeframe',payload_mtd,{headers: {
                Authorization: 'Bearer '+ this.$store.state.jwt
                    }}).then(response=>{
                        if(response.data.status==='success'){
                            if(response.data.data.length>0){
                                this.calculateScopeDataMTD(count,response.data.data,i.scope,i.machine_type)
                            }
                        }
                        count = count + 1
                    }).catch(err=>{
                        console.error(err)
                        this.loading=false
                    })
            }
        },
        getLMTDData(){
            // let from_date_lmtd;
            // let to_date_lmtd;
            let to_date_lmtd=moment().subtract(1,'day').subtract(1, 'month')
            // console.log(to_date_lmtd.format('YYYYMMDD'))
            let from_date_lmtd = moment().subtract(1,'day').subtract(1, 'month').startOf('month')
            
            // if (moment().date() === 1) {
            // // If it's the 1st day of the month, go back two months
            // from_date_lmtd = moment().subtract(2, 'months').startOf('month').format('YYYYMMDD');
            // to_date_lmtd = moment().subtract(1, 'days');
            // to_date_lmtd = moment().subtract(2, 'months').endOf('month').format('YYYYMMDD');
            // } else {
            // // For other days, go back one month
            // from_date_lmtd = moment().subtract(1, 'month').startOf('month').format('YYYYMMDD');
            // to_date_lmtd = moment().subtract(1, 'days');
            // to_date_lmtd = moment().subtract(1, 'months').format('YYYYMMDD');
            // }
            let count = 1
            for(let i of this.module){
                    let payload_lmtd={
                    module_id:i.module_id,
                    machine_id:i.machine_id,
                    from_date:from_date_lmtd.format('YYYYMMDD'),
                    to_date:to_date_lmtd.format('YYYYMMDD'),
                }
                axios.post(this.$store.state.api+'getESGModuleGroupedDataByTimeframe',payload_lmtd,{headers: {
                Authorization: 'Bearer '+ this.$store.state.jwt
                    }}).then(response=>{
                        if(response.data.status==='success'){
                            
                            this.calculateScopeDataLMTD(count,response.data.data,i.scope,i.machine_type)
                            
                        }
                        count = count + 1
                    }).catch(err=>{
                        console.error(err)
                        this.loading=false
                    })

            }
        },
    }
}
</script>