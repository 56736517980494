<template>
    <v-container fluid class="px-0 mx-0">
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-row v-if="showDialog">
            <v-col>
                <v-dialog
                  v-model="showDialog"
                  fullscreen
                  hide-overlay
                  transition="dialog-bottom-transition"
                  >
                    <ESGScopeDetails :machineDetailList="machineDetailList" :fromScopeCard="false" v-on:close="close()"/>
                </v-dialog>
            </v-col>
        </v-row>
        <v-row v-if="showDummyDialog">
            <v-col>
                <v-dialog
                  v-model="showDummyDialog"
                  fullscreen
                  hide-overlay
                  transition="dialog-bottom-transition"
                  >
                    <ESGScopeDummyDetails v-on:close="close()"/>
                </v-dialog>
            </v-col>
        </v-row>
        <v-card  :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().backgroundColorCode" flat>
        <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
            <v-row>
                <v-col>
                    <v-card :style="{'border-radius':'5px','border':'1px solid'+$store.getters.getColorPalette().panelBorderColorCode}" flat :dark="$store.getters.getColorPalette().isDark"  :color="$store.getters.getColorPalette().background2ColorCode">
                    <v-row>
                        <v-col>
                            <div>
                                <v-simple-table height="200px" :style="{background: 'transparent'}" >
                                <template v-slot:default>
                                        <thead>
                                                <tr >
                                                    <th colspan="3" :style="'color:'+$store.getters.getColorPalette().accentCode" class="text-center"><strong>{{$store.getters.getTextMap().scope_1}}</strong></th>
                                                </tr>
                                        </thead>
                                        <tbody>

                                            <tr @click="showDetail(item)" :key="item.machine_id" v-for="item in scope1Data">
                                                <td><strong>{{ item.machine_name.toUpperCase() }}</strong></td>
                                                <td><strong>{{  item.data.toFixed(2)}}  tCO₂</strong></td>
                                                <td>
                                                    <span v-if="item.machine_type==='solar'" style="padding:2%;background-color:#69F0AE;align-items: center; justify-content: center;"><v-icon style="align-items:center">mdi-arrow-down</v-icon></span>
                                                    <span v-else style="padding:2%;background-color:#FF5252;align-items: center; justify-content: center;"><v-icon style="align-items:center" >mdi-arrow-up</v-icon></span>
                                            </td>
                                            </tr>
                                            <tr @click="showDummyDetail()" v-if="$store.state.user.organisation_id==='26c7ea9e-8d15-4022-bd9e-e4166a6078e5'">
                                                <td><strong>LPG</strong></td>
                                                <td><strong>3.6</strong>  tCO₂</td>
                                                <td><span style="padding:2%;background-color:#FF5252;align-items: center; justify-content: center;"><v-icon style="align-items:center" >mdi-arrow-up</v-icon></span></td>
                                            </tr>
                                        </tbody>
                                    </template> 
                                </v-simple-table>
                            </div>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
            <v-col class="mx-0 pl-0">
            <v-card :style="{'border-radius':'5px','border':'1px solid'+$store.getters.getColorPalette().panelBorderColorCode}" flat :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().background2ColorCode">
                <v-row>
                    <v-col>
                    <div>
                        <v-simple-table height="200px" :style="{background: 'transparent'}" >
                            <template v-slot:default>
                                <thead>
                                        <tr >
                                            <th colspan="3" :style="'color:'+$store.getters.getColorPalette().accentCode" class="text-center"><strong>{{$store.getters.getTextMap().scope_2}}</strong></th>
                                        </tr>
                                </thead>
                                <tbody>
                                    <tr @click="showDetail(item)" :key="item.machine" v-for="item in scope2Data">
                                        <td ><strong>{{ item.machine_name.toUpperCase() }}</strong></td>
                                        <td ><strong>{{  item.data.toFixed(2) }}  tCO₂</strong></td>
                                        <td>
                                            <span v-if="item.machine_type==='solar'" style="padding:2%;background-color:#69F0AE;align-items: center; justify-content: center;"><v-icon>mdi-arrow-down</v-icon></span>
                                            <span v-else  style="padding:2%;background-color:#FF5252;align-items: center; justify-content: center;"><v-icon style="align-items:center">mdi-arrow-up</v-icon></span>
                                        </td>
                                    </tr>
                                </tbody>
                            </template> 
                        </v-simple-table>
                    </div>
                </v-col>
                </v-row>
                </v-card>
            </v-col>
            </v-row>
        </v-card>
    </v-container>
</template>

<script>
import moment from 'moment'
import axios from 'axios'
import InfoAlert from '@/components/InfoAlert'
import ESGScopeDetails from '@/components/modals/modules/esg/ESGScopeDetails'
import ESGScopeDummyDetails from '@/components/modals/modules/esg/ESGScopeDummyDetails'
export default {
    name:'ESGModuleMachineWiseTable',
    props:['module'],
    components:{
        InfoAlert,
        ESGScopeDetails,
        ESGScopeDummyDetails
    },
    data(){
        return{
            info:'',
            loading:false,
            showDismissibleAlert:false,
            scope1Data:[],
            scope2Data:[],
            tableData:{},
            machineDetailList:[],
            showDialog:false,
            showDummyDialog:false,
        }
    },
    mounted(){
        if(this.module){
            this.getData()
        }
    },
    methods:{
        calculateScopeDataMTD(count,db_data,scope,machine_name,machine_type,machine_id,module_id){
            let data_temp = 0
            for (let i of db_data){
                data_temp = i['data'] + data_temp
            }
            
            if(scope===1){
                if(machine_type==='solar'){
                    this.scope1Data.push({'machine_name':machine_name,'machine_type':machine_type,'data':data_temp*-1,'scope':scope,'machine_id':machine_id,'module_id':module_id})
                }else{
                    this.scope1Data.push({'machine_name':machine_name,'machine_type':machine_type,'data':data_temp,'scope':scope,'machine_id':machine_id,'module_id':module_id})
                }
            }else{
                this.scope2Data.push({'machine_name':machine_name,'machine_type':machine_type,'data':data_temp,'scope':scope,'machine_id':machine_id,'module_id':module_id})
            }
    },
        getData(){
            let from_date_mtd=moment().subtract(1,'days')
            from_date_mtd=from_date_mtd.startOf('month').format('YYYYMMDD')
            let count = 1
            for(let i of this.module){
                let payload_mtd={
                    module_id:i.module_id,
                    machine_id:i.machine_id,
                    from_date:from_date_mtd
                }
                axios.post(this.$store.state.api+'getESGModuleGroupedDataByTimeframe',payload_mtd,{headers: {
                Authorization: 'Bearer '+ this.$store.state.jwt
                    }}).then(response=>{
                        if(response.data.status==='success'){
                            this.calculateScopeDataMTD(count,response.data.data,i.scope,i.machine_name,i.machine_type,i.machine_id,i.module_id)
                        }
                        count = count + 1
                    }).catch(err=>{
                        console.error(err)
                        this.loading=false
                    })

            }
        },
        showDetail(item){
            this.machineDetailList = [item]
            this.showDialog = true
        },
        showDummyDetail(){
            this.showDummyDialog = true
        },
        close(){
            this.showDialog = false   
            this.showDummyDialog = false   
        }
    }
}
</script>