<template>
  <v-container fluid >
    <v-container class="pa-1" v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess']) ">
      <v-row  no-gutters>
        <v-col align="right" >
           <CreateModuleWindow/>
         </v-col>
      </v-row>  
    </v-container>
      <v-row no-gutters>
        <v-col>
          <v-tabs
            :dark="$store.getters.getColorPalette().isDark"
            :background-color="$store.getters.getColorPalette().backgroundColorCode"
            show-arrows
            v-model="tab"
          >
            <v-tabs-slider :color="$store.getters.getColorPalette().accentCode"></v-tabs-slider>
            <router-link  v-for="entureModule in $store.state.entureModules" :key="entureModule.module_id"  :style="{'color':'inherit','display': 'flex','justify-content':'space-between','align-item':'center','text-decoration': 'none'}" :to="`/modules/${entureModule.module_type}/${entureModule.module_id}`">
              <v-tab><v-icon :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" class='mr-2' small>{{icons[entureModule.module_type]}}</v-icon>{{entureModule.name}}</v-tab>
            </router-link>
          </v-tabs>
          <v-divider :color="$store.getters.getColorPalette().accentCode"></v-divider>        
        </v-col>
      </v-row>
      <v-row v-if="$store.state.entureModules.length==0">
        <v-col align="center">
          <v-icon :dark="$store.getters.getColorPalette().isDark" large>mdi-hail</v-icon><h3 :style="{color:$store.getters.getColorPalette().accentCode}" >No Modules Configured</h3>
        </v-col>
      </v-row>
      <router-view></router-view>
    </v-container>
  </template>
  <!-- <v-row no-gutters>
    <v-col cols="12">
      <v-tabs-items v-model="tab">
<v-tab-item
v-for="entureModule in $store.state.entureModules"
:key="entureModule.module_id"
>

<component v-bind:is="moduleHash[entureModule.module_type]" :entureModule="entureModule"></component>
</v-tab-item>
      </v-tabs-items>
    </v-col>
  </v-row> -->
<script>
import CreateModuleWindow from '@/components/modals/CreateModuleWindow'
import BusbarModulePage from '@/pages/modules/BusbarModulePage'
import PQModulePage from '@/pages/modules/PQModulePage'
import ESGModulePage from '@/pages/modules/ESGModulePage'

export default {
  name:'EntureModules',
  components:{
    CreateModuleWindow,

  },
  created() {
    // Redirect to the first workspace ID
    if (this.$store.state.entureModules && this.$store.state.entureModules.length > 0) {
      const firstModuleId = this.$store.state.entureModules[0].module_id;
      this.$router.push(`/modules/${this.$store.state.entureModules[0].module_type}/${firstModuleId}`);
    }
  },
  mounted() {
    document.title='Modules'
         if (!this.$store.state.loggedIn) {
      this.$router.push("/");
    }
    },
  data(){
    return {
      tab:null,
      moduleHash:{
        'busbar_temp':BusbarModulePage,
        'pq':PQModulePage,
        'esg':ESGModulePage
      },
      icons:{
        "esg":'mdi-pine-tree-variant-outline',
        "pq":'mdi-transmission-tower',
        "busbar_temp":'mdi-thermometer-low',
      }
    }
  }
}
</script>
<style scoped>
.text{
  color:#81D4FA;
}
.theme--light.v-tabs-items{
  background-color: #263238;
}
</style>